import React from 'react'
import { message, Upload, Icon, Modal, Carousel, Row, Col, Button,Form, Spin,Input, Cascader} from 'antd';

import App from '../App'
const FormItem = Form.Item;
const s3_Region = process.env.S3_REGION
const bucket = process.env.BUCKET
const S3_Url = process.env.S3_URL
class Banner extends React.Component{
    constructor(props){
        super(props);
        this.state={
            loading:false,
            previewVisible: false,
            previewImage: '',
            bannerImages:[],
            prevBannerImages: [],
            mobileData:[]
        }
    }
            handleCancel = () => this.setState({ previewVisible: false })
            enterLoading = () => {
                this.setState({ loading: true });
            }
            messageClose = () => {
                this.setState({ loading: false });
                // this.props.history.push('/homepage');
                // location.reload(true)

            }
            handlePreview = (file) => {
                this.setState({
                previewImage: file.url || file.thumbUrl,
                previewVisible: true,
                });
            }

            onChange = (value) => {
                // this.setState({
                return  { parentMenu:value.join('/')} 
                
                // })
            }
            flatToHierarchy = (flat) => {

                var roots = [] // things without parent
            
                // make them accessible by guid on this map
                var all = {}
                
                flat.forEach(function(item) {
                  all[item.menuItemPath] = item
                })
                
                // connect childrens to its parent, and split roots apart
                Object.keys(all).forEach(function (menuItemPath, menuKey) {
                    var item = all[menuItemPath]
                    if (item.parentPath === "/") {
                        item.value=item.slugUrl
                        item.label=item.title
                        roots.push(item)
                    } else if (item.parentPath in all) {
                        var p = all[item.parentPath]
                        item.value=item.slugUrl
                        item.label=item.title
            
                        if (!('children' in p)) {
                            p.children = []
                        }
                        
                        p.children.push(item)
                    }
                })
                // done!
                return roots
              }
              static getDerivedStateFromProps(nextProps, nextState){
    
                const data = nextProps.data
                let addImages = []
                if(data != null){
                if(nextState.prevBannerImages != nextState.bannerImages && nextState.bannerImages.length > 0){
                  return{
                    bannerImages: nextState.bannerImages,
                  }
                
                }else{
                  if(nextState.bannerImages.length<nextState.prevBannerImages.length){
                    return {
                      bannerImages:nextState.bannerImages
                    }
                  }else{
                  data.map((addImg, addImKey) => {
                      addImages.push ({
                          key:addImg.homePageBanner.key,
                          uid: addImKey,
                          bucket:addImg.homePageBanner.bucket,
                          region:addImg.homePageBanner.region,
                          url:`${S3_Url}${addImg.homePageBanner.key}`
                      })
                  })}
                  return{
                    bannerImages:addImages,
                    prevBannerImages: addImages,
                    customSiteId:nextProps.customSiteId,
                    dataimg:data
                  }
                }
              }
              
              return null
            }
    render(){
        const { getFieldDecorator } = this.props.form;
        const { loading, previewVisible, previewImage, bannerImages, mobileData } = this.state;
        const uploadButton = (
                <div>
                    <Icon type="plus" />
                    <div>Upload</div>
                </div>
                );
        return(
            <App header={"Banners"}>
                <h2>Banners</h2>
                <Form onSubmit={this.handleSubmit}>
          <div style={{ background: '#ECECEC' }}>
            <Row >
              <Col >
                {bannerImages.length > 0 &&
                  <Carousel autoplay={true} dots={false} arrows={false}>
                    {this.handleimage(bannerImages)}
                  </Carousel>
                }
              </Col>
            </Row>
          </div>
          
          {loading &&
            <div style={{ textAlign: "center" }}>
              <Spin size="large"></Spin>
            </div>
          }

          {!loading &&
          
          <div style={{ marginBottom: 16, marginTop: 16 }}>
          

            {bannerImages.length > 0 ?
              <FormItem>
                {getFieldDecorator('bannerImage', {
                   trigger: 'onBlur',
                   valuePropName: 'defaultValue',
                  initialValue:bannerImages,
                  rules: [{
                    required: true,
                    message: " Images are required"
                  }]
                })(
                <Upload
                  listType="picture-card"
                  fileList={bannerImages}
                  defaultFileList={bannerImages.length > 0 ? bannerImages.key : null}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  onRemove={this.handleRemove}
                >
                {Object.keys(bannerImages).length >= 10 ? null : uploadButton}
                </Upload>
                )}
              
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </FormItem>

              :
             
              <FormItem>
                {getFieldDecorator('bannerImage', {
                   trigger: 'onBlur',
                   valuePropName: 'defaultValue',
                  rules: [{
                    required: true,
                    message: " Images are required"
                  }]
                })(
                <Upload
                fileList={bannerImages}
                  listType="picture-card"
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                >
                  {bannerImages.length >= 10 ? null : uploadButton}

                  {/* {bannerImages.length >= 6 ? null : uploadButton} */}
                </Upload>
                )}
              
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </FormItem>
              

            }
             
                      {
              this.state.bannerImages.map((val,index)=>{
                  return (
                    <Row gutter={24}>
                      <Col span={12}>
                    <FormItem label={`Banner Image Link ${index + 1}`} >
                      {getFieldDecorator(`link[${index}]domain`, {
                          initialValue:this.state.dataimg[index] ? this.state.dataimg[index].menuItemPath : "",
                              rules: [{
                                  required: true,
                                  message: "Please enter link.",
                              }],
                        })(
                          <Input placeholder="Banner Image Link" />
                        )}
                        
                    </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label={`Banner Title ${index + 1}`}>
                      {getFieldDecorator(`bannerTitle[${index}]`, {
                          validateTrigger: ['onChange', 'onBlur'],
                          initialValue:this.state.dataimg[index] ? this.state.dataimg[index].bannerTitle : "",
                          rules: [{
                          required: true,
                          message: "Please input banner title.",
                          }],
                      })(
                          <Input placeholder="Banner title" />
                      )}
                   </FormItem>
                   </Col>
                   </Row>
                   )
                })
            }
            
            <FormItem>
            <Button htmlType="submit" type="primary"  disabled={bannerImages == '' ? true : false} style={{ background:"#389e0d", color:"#fff", marginBottom: 16}} >
              Submit
            </Button>
           </FormItem>
        
        
          </div>
          }
        </Form>

            </App>
        )
    }
}
const wrappedBanner = Form.create()(Banner);
export default wrappedBanner