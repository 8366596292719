import gql from 'graphql-tag'
export default gql`

mutation updateMerchant(
  $mob: String!
  $email: String
  $fName: String!
  $lName: String!
  $businessName: String!
  $services: [String!]
  $searchFiled: String
  $saloonType: SALOON_TYPE
  $gstNo: String
  $enabled: Boolean
  $address: GenericAddressInput
  $loc: LatLngInput
  $area: String
  $mainImage: S3ObjectInput
  $additionalImages: [S3ObjectInput!]
  $workingDays: [String!]
  $workingTimeFrom: String
  $workingTimeTo: String
){
  updateMerchant(input:{
    mob: $mob
    email: $email
    fName: $fName
    lName: $lName
    businessName: $businessName
    services: $services
    searchFiled: $searchFiled
    saloonType: $saloonType
    gstNo: $gstNo
    enabled: $enabled
    address: $address
    loc: $loc
    area: $area
    mainImage: $mainImage
    additionalImages: $additionalImages
    workingDays: $workingDays
    workingTimeFrom:$workingTimeFrom
    workingTimeTo: $workingTimeTo
  }){
      id
      userType
      mob
      email
      fName
      lName
      businessName
      services
      searchFiled
      saloonType
      gstNo
      enabled
      address{
        addressLineOne
        addressLineTwo
        city
        state
        zip
        country
      }
    loc{
      lat
      lng
    }
    area
    workingDays
    workingTimeFrom
    workingTimeTo
    createdAt
    mainImage{
      bucket
      region
      key
    }
    additionalImages{
      bucket
      region
      key
    }
  }
}
`