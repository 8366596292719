import React from 'react'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Divider, Popconfirm, Form, Input,message } from 'antd';
import Highlighter from 'react-highlight-words';
import { compose, graphql, withApollo } from 'react-apollo'

import moment from 'moment';
import getMerchants from '../query/getMerchants'
import App from '../App'
const limit = 40
class Marchant extends React.Component{
    constructor(props){
        super(props) 
        this.state={
            merchantData:[],
            loading:false,
            nextToken: "",
            pagination: {},
        }
    }
    enterLoading = ()=>{
        this.setState({loading:true})
    }
    componentDidMount(){
      this.fetch();
      }
      handleTableChange = (pagination, filters, sorter) => {
        // skip = skip + limit 
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
          loading: true,
          search:false
        });
        if (this.state.nextToken == null) {
              this.setState({
                hasMore: false,
                onSearch: false,
                loading: false,
                search:false
         });
         return;
        }
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
        // this.fetch();
      };
    
      fetch = (params = {}) => {
        this.setState({ loading: true });
        this.props.client
          .query({
            query: getMerchants,
            variables: {
              status: "APPROVED",
              nextToken: this.state.nextToken,
              limit: limit
            },
            fetchPolicy:"network-only"
          }).then(({ data }) => {
            const dgf = data.getMerchants.items
          const pagination = { ...this.state.pagination };
          const token =data.getMerchants.nextToken
         let all = this.state.merchantData.concat(dgf) 
          this.setState({
            merchantData: all,
            nextToken: token,
            loading: false,
            pagination,
          });
        })
        };
      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search here`}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
          if (dataIndex == 'upn') {
            return record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          } else {
            let a = dataIndex.split('.')[1].toString();
            return record.masterVariationDump[a]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
        });
      };
    

    render(){
      // console.log("data ren",this.state.merchantData)
        const columns = [ 
               
              // {
              //   title: 'User Name (Seller Login)',
              //   dataIndex: 'userName',
              //   key: 'userName',
              //   ...this.getColumnSearchProps('userName'),
              // },
              {
                title: 'First Name',
                dataIndex: 'fName',
                key: 'fName',
                ...this.getColumnSearchProps('fName'),
              },
              {
                title: 'Last Name',
                dataIndex: 'lName',
                key: 'lName',
                ...this.getColumnSearchProps('lName'),
              }, {
                title: 'Shop Name',
                dataIndex: 'businessName',
                key: 'businessName',
                ...this.getColumnSearchProps('businessName'),
              },
              {
                title: 'Mobile Number',
                dataIndex: 'mob',
                key: 'mob',
                ...this.getColumnSearchProps('mob'),

              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Date',
                dataIndex: 'createdAt',
                key:'createdAt',
                sorter: (a, b) => {
                  return a.createdAt -  b.createdAt
                },
                render: (date) => {
                  var dateString = moment.unix(date).format("DD/MM/YYYY");
                  return(
                      <span>{dateString}</span>
                  )
              }
              },
               {
                        title: 'Action',
                        key: 'action',
                        render: (data) => {
                                return (
                                    <span>
                                        <Link to={{pathname:"/editmerchant",
                                            state:{merchantData:data}
                                        }}>
                                            <Button type="primary" icon="edit" style={{ cursor: "pointer",marginRight:"10px" }}/>
                                        </Link>
                                        {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.mob,data.userName)}>
                                            <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
                                        </Popconfirm> */}
                                    </span>
                                )
                            }
                        
                }
    
            ];
        return(
            <App header={"Manage Merchant"}>
            <Row gutter={16}>
                {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                    <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Breadcrumb.Item>Marchant List</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <div class="flex-container">
                    <Link to={{ pathname :"/jmservice" }}>
                          <Button type="default" style={{ marginBottom: 16, marginRight: 5, marginLeft: 10, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                          Manage Service
                          </Button>
                      </Link>
                   
                    <Link to={{ pathname :"/createMarchant" }}>
                        <Button type="default" style={{ marginBottom: 16, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                        Create Marchant
                        </Button>
                    </Link>
                </div> */}
                <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Merchant</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                        {/* <Link to="/">
                            <Link to="/createMarchant">
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Merchant
                                </Button>
                            </Link>
                        </Link> */}
                    </Col>
                </Row>
                <Table columns={columns} loading={this.state.loading} onChange={this.handleTableChange} dataSource={this.state.merchantData} pagination={this.state.pagination} />

            </App>
        );
    }
}


// const wrapMarchant = compose(
//   withApollo,
//   graphql(getMerchants,{
//       name:'getMerchants'
//   })

// )(Marchant)
export default withApollo(Marchant)