import React from 'react'
import { Button, Col, Divider, Form, Input, Row, Select, Table, message } from 'antd'
import {compose, graphql, withApollo} from 'react-apollo';
import App from '../App'
import updateMerchant from '../mutation/updateMerchant'
import getServiceCategories from '../query/getServiceCategories'
const FormItem = Form.Item
const Option = Select.Option;
const status = [{key:0, label:"ACTIVE", value:"true"}, {key:1, label:"INACTIVE", value:'false'}]
const salontype = [{key:0, label:"Mens", value:"MENS"},{key:1, label:"Women", value:"WOMEN"},{key:2, label:"Unisex", value:"UNISEX"},]
const country = [
    { value: 'IND', label: 'India' },
    { value: 'USA', label: 'USA' },
    { value: 'UK', label: 'UK '}
]
const columns = [{
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
}, {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
}, {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
}, {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
}, {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
}]

const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]
class MarchantreqEdit extends React.Component{
    constructor(props){
    super(props)
    this.state={
        loading:false,
            selectedZipcode: ['all'],
            selectedCity: '',
            selectedState: '',
            selectedCountry: '',
            countryData: [],
            stateData: [],
            cityData: [],
            zipData: ['all'],
            selectedLocations: [],
            locationError: false,
            dataSource:[],
            name:'',
            categoryData: [],
    }
    
}

componentDidMount(){
      this.props.client.query({
        query: getServiceCategories,
        fetchPolicy: 'network-only' 
      }).then(({data})=>{
          console.log("category",data.getServiceCategories.items)
        //   var dd = data.getServiceCategories.items
        this.setState({
          categoryData:data.getServiceCategories.items,
        })
      }).catch((err)=>{
        console.log(err)
      })
    }
    enterLoading = ()=>{
      this.setState({loading:true})
  }
  handleclick =()=>{
    this.props.history.push('/merchantRe')
}
handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
        console.log("submit",values)
      if (!err) {
          this.enterLoading()
        console.log('Received values of form: ', values);
                        this.props.client.mutate({
                            mutation: updateMerchant,
                            variables: {
                                userType:values.salontype,
                                mob:values.mob,
                                email:values.email,
                                fName:values.fName,
                                lName:values.lName,
                                businessName:values.businessName, 
                                services: values.services,
                                saloonType:values.salontype,
                                gstNo:values.gstNo ? values.gstNo : null,
                                enabled:values.enabled,
                                address:{
                                addressLineOne:values.addressLineOne,
                                addressLineTwo:values.addressLineTwo ? values.addressLineTwo : null,
                                city:values.city,
                                state:values.state,
                                zip:values.zip,
                                country:"IND",
                                },
                            }
                        }).then(({data}) => {
                            console.log("susses",data)
                            // message.success('Merchant Updated Successfully');
                            if(data.updateMerchant){
                                this.setState({
                                    loading:false
                                })
                            message.success('Merchant Updated Successfully',this.props.history.push('/merchantRe'));
                            
                            }
                            }).catch(res => {
                                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
                                message.error('Something went wrong', 3);
                                
                            });
      }else{
          console.log(err)
      }

    });
  };
 
render(){
    
    let ddd = this.state.categoryData
    
    // console.log('ddd',ddd)
    
    const { getFieldDecorator, getFieldValue } = this.props.form;
    let merchantData = this.props.location.state.merchantData

    return(
        <App header={"Edit Merchant"}>
           <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Business Name">
                                {getFieldDecorator(`businessName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: merchantData.businessName?merchantData.businessName:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Business Name",
                                    },
                                    // {
                            
                                    //     validator:(rule, value, cb)=>{
                                    //       if(value){
                                    //         if(!value.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                                    //           cb('Please enter valid Email address')
                                    //         }
                                    //       }
                                    //         cb()
                                    //     }},
                                ]
                                })(
                                    <Input placeholder="Business Name"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator(`email`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.email?merchantData.email:'',
                                    rules: [{
                                        type: 'email',
                                        required: true,
                                        message: "Please enter E-mail!",
                                    },
                                    // {
                            
                                    //     validator:(rule, value, cb)=>{
                                    //       if(value){
                                    //         if(!value.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                                    //           cb('Please enter valid Email address')
                                    //         }
                                    //       }
                                    //         cb()
                                    //     }},
                                    
                                
                                ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="First Name">
                                {getFieldDecorator(`fName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.fName?merchantData.fName:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter First Name",
                                    }]
                                })(
                                    <Input placeholder="First Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Last Name"
                            >
                                {getFieldDecorator(`lName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.lName?merchantData.lName:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Last Name",
                                    }]
                                })(
                                    <Input placeholder="Last Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mob`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    initialValue:merchantData.mob?merchantData.mob:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)){
                                              cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }}
                            ]
                                })(
                                    <Input placeholder="Mobile Number" disabled="true" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="GST Number"
                            >
                                {getFieldDecorator(`gstNo`, {
                                //    trigger: 'onBlur',
                                //    valuePropName: 'defaultValue',
                                   initialValue:merchantData.gst?merchantData.gst: null,
                                    rules: [{
                                        required: false
                                    }]
                                })(
                                    <Input placeholder="GST Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Salon Type"
                            >
                                {getFieldDecorator(`saloonType`, {
                                //    trigger: 'onBlur',
                                //    valuePropName: 'defaultValue',
                                   initialValue:merchantData.saloonType?merchantData.saloonType:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Salon Type",
                                    }]
                                })(
                                    <Select placeholder="Select"  style={{ width: '100%', marginRight: 8 }}>
                                        {
                                            salontype.map((tvalue) => {
                                                return (<Option key={tvalue.value}>{tvalue.label}</Option >)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        
                        
                        
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Address 1"
                            >
                                {getFieldDecorator(`addressLineOne`, {
                                //    trigger: 'onBlur',
                                //    valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.addressLineOne?merchantData.address.addressLineOne:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Address 1",
                                    }]
                                })(
                                    <Input placeholder="Address 1" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Address 2"
                            >
                                {getFieldDecorator(`addressLineTwo`, {
                                //    trigger: 'onBlur',
                                //    valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.addressLineTwo?merchantData.address.addressLineTwo:null,
                                    rules: [{
                                        required: false,
                                        message: "Please enter Address 2",
                                    }]
                                })(
                                    <Input placeholder="Address 2" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="City"
                            >
                                {getFieldDecorator(`city`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.city?merchantData.address.city:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter city",
                                    }]
                                })(
                                    <Input placeholder="City" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="State"
                            >
                                {getFieldDecorator(`state`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.state?merchantData.address.state:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter state",
                                    }]
                                })(
                                    <Select
                                    optionFilterProp="children"
                                    placeholder="Select State"
                                    showSearch
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  >
                                    {state.map((c)=>{
                                        return(
                                          <Option key={c.value} value={c.value} >{c.label}</Option>
                                        )
                                    }) }
                                  </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Pincode"
                            >
                                {getFieldDecorator(`zip`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.zip?merchantData.address.zip:'',

                                    rules: [{
                                        required: true,
                                        message: 'Please enter pincode'
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && isNaN(value.trim())){
                                              cb('Please enter number only')
                                            }
                                            cb()
                                        }}
                                    ]
                                })(
                                    <Input placeholder="Pincode" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                       
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem 
                            label="Status"
                            >
                                {getFieldDecorator(`enabled`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    initialValue:merchantData.status == "false" ? "false" : "true",
                                    rules: [{
                                        required: true,
                                        message: "Please select status.",
                                    }],
                                })(
                                    <Select placeholder="Select"  style={{ width: '100%', marginRight: 8 }}>
                                        {
                                            status.map((tvalue) => {
                                                return (<Option key={tvalue.value}>{tvalue.label}</Option >)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                        <Form.Item label="Services">
                            {getFieldDecorator('services', {
                                valuePropName: 'defaultValue',
                                initialValue: merchantData.services?merchantData.services:'',
                    
                                rules: [
                                { required: true, message: 'Please select Services!', type: 'array' },
                                ],
                            })(
                                <Select mode="multiple" placeholder="select Services" mode="tags" tokenSeparators={[',']} style={{width:"100%"}}>
                                {
                                     this.state.categoryData.map((val)=>{
                                        //  console.log("val",val)
                                    return(
                                        <Option value={val.slugServiceName}>{val.serviceName}</Option>
                                    )
                                    })
                                }
                                </Select>
                            )}
                            </Form.Item>
                        </Col>
                    </Row>
        
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
        </App>
    )
}
}
const WrappedMarchantreqEdit = Form.create()(MarchantreqEdit);
export default withApollo(WrappedMarchantreqEdit)