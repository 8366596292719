import React from 'react'
import { Select, Form, Input, Button, Upload, Modal, Row, Col, Radio, message,Breadcrumb, Menu, Dropdown, Icon} from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom'
import slugify from 'slugify';
import createServiceCategory from '../mutation/createServiceCategory'
import AppSy from '../configs/AppSy';
import App from '../App'
const FormItem = Form.Item;
const bucket = AppSy.bucket ? AppSy.bucket : 'saloon-app-dev';
const region = AppSy.region ? AppSy.region : 'ap-south-1';
class CreateServices extends React.Component{
    constructor(props){
        super(props)
        this.state={
            iconImg: {},
            removedIconImg: false,
            value: true,
        }
    }
    onChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
                return{
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else{
                return{
                    // iconImg: {},
                    removedIconImg: false
                }
            }
        });
    }

    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return true;
    }
    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }
   
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            // console.log('Received values of form: ', values);
            const slugName = slugify(values.serviceName, {
                lower: true
            })
            if(Object.keys(this.state.iconImg).length > 0){
                values.iconImg = {
                    bucket: bucket,
                    region: region,
                    localUri:this.state.iconImg.originFileObj,
                    key:`Category/${values.serviceName}/${this.state.iconImg.name}`,
                    mimeType:this.state.iconImg.type
                } 
              }
                            this.props.client.mutate({
                                mutation: createServiceCategory,
                                variables: {
                                    serviceId:"category",
                                    slugServiceName: slugName,
                                    serviceName: values.serviceName,
                                    enabled: this.state.value,
                                    type: "category",
                                    searchFiled: values.serviceName.toLowerCase(),
                                    photo:values.iconImg && Object.keys(values.iconImg).length > 0 ? values.iconImg : undefined,
                                }
                            }).then((data) => {
                                // console.log(data)
                                message.success("data sumitted sucessfully");
                                })
          }
        });
      };
      handleclick =()=>{
        this.props.history.push('/manage_services')
    }


    render(){
        const { getFieldDecorator } = this.props.form;
        const { previewVisible, previewImage, iconImg } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>Upload</div>
            </div>
            
            );
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return(
            <App header={"CreateServices"}>
               <Form layout='vertical' onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Service Name"
                            >
                                {getFieldDecorator(`serviceName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Service Name",
                                    }]
                                })(
                                    <Input placeholder="Service Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Photo"  extra="Add square image.">
                            {getFieldDecorator('icon', {
                                // validateTrigger: ['onChange','onBlur'],
                                rules: [{
                                    required: true,
                                    message: "Photo is required",
                                }],
                            })(
                                <Upload
                                    listType="picture-card"
                                    data={iconImg}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleMainImage}
                                    onRemove={this.handleMainImageRemove}
                                >
                                    {Object.keys(iconImg).length != 0 ? null : uploadButton}
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </FormItem>
                      </Col>
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Status" {...formItemLayout}>
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio value={true}>Active</Radio>
                                <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                      </FormItem>
                      </Col>
 
                      
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                        </Form>
            </App>
        );
    }
}
const WrappedCreateServices = Form.create()(CreateServices);
export default withApollo(WrappedCreateServices)
