import gql from 'graphql-tag'
export default gql`
query getServiceCategories( $nextToken: String){
  getServiceCategories(nextToken: $nextToken){
    items{
      id
      serviceId
      slugServiceName
      serviceName
      enabled
      type
      createdAt
      photo{
        bucket
        region
        key
      }
      serviceSubCategories{
      id
      serviceId
      slugServiceName
      serviceName
      description
      rate
      merchantId
      attachedCategory
      type
      enabled
      createdAt
      merchantDetails{
        businessName
      }
      photo{
        bucket
        region
        key
      }
    }
    }
  }
}
`

