import React from 'react'
import App from '../App'
class CustomerEmail extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        return(
            <App header={"Email"}>
                <h1>emails</h1>
            </App>
        )
    }
}
export default CustomerEmail