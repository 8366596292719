import React from 'react'
import { Select, Form, Input, Button, Upload, Modal, Row, Col, message,Breadcrumb, Menu, Dropdown, Icon} from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import { Link } from 'react-router-dom'
import App from '../App'
import AppSy from '../configs/AppSy';
import MapComponent from './mapSearchBox'
import updateSiteSetting from '../mutation/updateSiteSetting'
import getSiteSetting from '../query/getSiteSetting'
const bucket = AppSy.bucket ? AppSy.bucket : 'saloon-app-dev';
const region = AppSy.region ? AppSy.region : 'ap-south-1';
const Option = Select.Option;
const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]

const FormItem = Form.Item;
class Setting extends React.Component{
    constructor(props){
        super(props)
        this.state={
            visible:false,
            latlng:{},
            position:this.props.location.state != undefined &&
            this.props.location.state.exhibitorData!=null && 
             this.props.location.state.exhibitorData.latlng != null ? 
              this.props.location.state.exhibitorData.latlng:undefined,
            loading:false,
            lat:null,
            settingData: '',
            previewVisible: false,
            removedphoto: false,
            previewImage: '',
            photo:[],
            locationSaved:null,
            fileList:[],
            locatioError:false
        }
    }

    messageClose = () => {
        this.setState({loading:false})
      };
   
    
    siteHandleChange1 = ( {fileList} ) => {
        // console.log("fileList",fileList);
        this.setState({
            fileList:fileList
        })
        
      }

    handleOpen = () => this.setState({ visible: !this.state.visible })
    handleCancel = () => this.setState({visible:false})

    getPosition=(positions)=>{
        console.log("pos",positions)
      this.setState({
        position:positions,
        lat:positions,
        locationSaved: true,
        locatioError:false
      })
    }
    enterLoading = ()=>{
        this.setState({
            loading:true
        })
    }
    componentDidMount(){
        // this.enterLoading()
        this.props.client.query({
            query:getSiteSetting,
            fetchPolicy: 'network-only' ,
            })
            .then(({ data }) => {
              console.log("data1",data)
              if(data.getSiteSetting.location!=null && data.getSiteSetting.location!=undefined)
              {
                  this.setState({
                      position:data.getSiteSetting.location,
                      loading: false
                  })
              }
               this.setState({
                settingData: data.getSiteSetting,
                // position:data.getSiteSetting.location,
                loading:false,
              },()=>{this.imgSetting(data.getSiteSetting)});
            })
            .catch(err => {
                console.log(`Error ${JSON.stringify(err)}`)
            })
        }

        imgSetting=(data)=>{
           
            if(data!=null){
                let img = data.banners != null ?  data.banners :[]
                let newImg=[]
                img.map((val)=>{
                    newImg.push({
                        key:val.key,
                        uid:val.key,
                        bucket:val.bucket,
                        region:val.region,
                        url:`https://${val.bucket}.s3.${val.region}.amazonaws.com/${val.key}`
                    })
                 })
                           this.setState({
                                fileList:newImg,
                              })
             }
        }


       
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(this.state.position==undefined){
                this.setState({
            locatioError:true,
                })
                return false
            }
            else{
                this.setState({
                    locatioError:false,
                        })
            }
            
            if (!err) {
                // console.log('Received values of form: ', values);
                if (this.state.fileList.length <= 5) {

                    let image = []
                    values.fileList = this.state.fileList.map((p) => {
                        console.log("p",p)
                        if (p && !p.hasOwnProperty('key')) {
                            return {
                                bucket: bucket,
                                region: region,
                                localUri: p.originFileObj,
                                key: `Settings/${p.originFileObj.name}`,
                                mimeType: p.type
                            }

                        }
                        else {
                            return {
                                bucket: bucket,
                                region: region,
                                key: p.key
                            }
                        }
                    })
                    //   image.push(values.fileList)
                    //   console.log("img", image)

                }
                console.log("file", values.fileList)
                // return

                this.enterLoading();
                this.props.client.mutate({
                    mutation: updateSiteSetting,
                    variables: {
                        companyName: values.companyName,
                        whatsAppNum: values.whatsAppNum,
                        contactEmail: values.contactEmail,
                        facebook: values.facebook,
                        instagram: values.instagram,
                        youtube: values.youtube,
                        banners: values.fileList,
                        location:{
                            lat:this.state.position!=undefined && this.state.position!=null ? this.state.position.lat:undefined,
                            lng:this.state.position!=undefined && this.state.position!=null ? this.state.position.lng:undefined,
                          },
                        address: {
                            addressLineOne: values.addressLineOne,
                            addressLineTwo: values.addressLineTwo ? values.addressLineTwo :null,
                            city: values.city,
                            state: values.state,
                            zip: values.zip,
                            country: "IND",
                        }

                    }
                }).then(({ data }) => {
                    // console.log(data)
                    message.success("Setting Update sucessfully");
                    this.setState({
                        loading: false
                    })
                    // if (data.updateSiteSetting) {
                  
                    // }
                })
            }
            else{
                console.log(err)
            }
        });
    };
  
    render(){
        const { getFieldDecorator, getFieldValue  } = this.props.form;
        const data = this.state.position ? this.state.position : undefined;

        // console.log("set data",this.state.settingData.address);
        // let add = this.state.settingData.address;
        let setdata = this.state.settingData != null && this.state.settingData != undefined ? this.state.settingData : ''
        const { photo , previewVisible,previewImage} =this.state
        // console.log("addressLineOne data",setdata)
            
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>Upload</div>
            </div>
            
            );
        return(
            <App header={"Settings"}>
                 
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Company Name"
                            >
                                {getFieldDecorator(`companyName`, {
                                   
                                   initialValue: setdata.companyName != null && setdata.companyName != undefined ? setdata.companyName : '',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Company Name",
                                    }]
                                })(
                                    <Input placeholder="Company Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                    <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Whats App Number" >
                          {getFieldDecorator('whatsAppNum', {
                              initialValue: setdata.whatsAppNum != null && setdata.whatsAppNum != undefined ? setdata.whatsAppNum : '',
                              rules: [{
                                //   whitespace: true,
                                  required: true,
                                  message: "Please Enter Whats App Number",
                              }],
                          })(
                              <Input placeholder="Whats App Number" />
                          )}
                      </FormItem>
                      </Col>
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Contact Email ID" >
                          {getFieldDecorator('contactEmail', {
                              initialValue:setdata.contactEmail != null && setdata.contactEmail != undefined ? setdata.contactEmail : '',
                              rules: [{
                                //   whitespace: true,
                                  required: true,
                                  message: "Please input Contact Email ID",
                              }],
                          })(
                              <Input placeholder="Contact Email ID" />
                          )}
                      </FormItem>
                      </Col>
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Address 1"
                            >
                                {getFieldDecorator(`addressLineOne`, {
                                   initialValue: setdata != null && setdata.address != null && setdata.address.addressLineOne ? setdata.address.addressLineOne: "",
                                    rules: [{
                                        required: true,
                                        message: "Please enter Address 1",
                                    }]
                                })(
                                    <Input placeholder="Address 1" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Address 2"
                            >
                                {getFieldDecorator(`addressLineTwo`, {
                                    initialValue: setdata != null && setdata.address != null && setdata.address.addressLineTwo ? setdata.address.addressLineTwo: "",
                                    rules: [{
                                        required: false,
                                        message: "Please enter Address 2",
                                    }]
                                })(
                                    <Input placeholder="Address 2" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="City"
                            >
                                {getFieldDecorator(`city`, {
                                    initialValue: setdata != null && setdata.address != null && setdata.address.city ? setdata.address.city: "",
                                    rules: [{
                                        required: true,
                                        message: "Please enter city",
                                    }]
                                })(
                                    <Input placeholder="City" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="State"
                            >
                                {getFieldDecorator(`state`, {
                                    initialValue: setdata != null && setdata.address != null && setdata.address.state ? setdata.address.state: "",
                                    rules: [{
                                        required: true,
                                        message: "Please enter state",
                                    }]
                                })(
                                    <Select
                                    optionFilterProp="children"
                                    placeholder="Select State"
                                    showSearch
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  >
                                    {state.map((c)=>{
                                        return(
                                          <Option key={c.value} value={c.value} >{c.label}</Option>
                                        )
                                    }) }
                                  </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Pincode"
                            >
                                {getFieldDecorator(`zip`, {
                                   initialValue: setdata != null && setdata.address != null && setdata.address.zip ? setdata.address.zip: "",
                                    rules: [{
                                        required: true,
                                        message: 'Please enter pincode'
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && isNaN(value.trim())){
                                              cb('Please enter number only')
                                            }
                                            cb()
                                        }}
                                    ]
                                })(
                                    <Input placeholder="Pincode" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Facebook Link">
                          {getFieldDecorator('facebook', {
                               initialValue: setdata.facebook,
                              rules: [{
                                //   whitespace: true,
                                  required: true,
                                  message: "Please input Facebook Link",
                              }],                                
                          })(
                              <Input placeholder="Facebook Link"  />
                          )}
                      </FormItem>
                      </Col>
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Instagram Link">
                          {getFieldDecorator('instagram', {
                             initialValue: setdata.instagram,
                              rules: [{
                                //   whitespace: true,
                                  required: true,
                                  message: "Please input Instagram Link",
                              }],                                
                          })(
                              <Input placeholder="Instagram Link"  />
                          )}
                      </FormItem>
                      </Col> 
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="YouTube Link">
                          {getFieldDecorator('youtube', {
                             initialValue: setdata.youtube,
                              rules: [{
                                //   whitespace: true,
                                  required: true,
                                  message: "Please input YouTube Link",
                              }],                                
                          })(
                              <Input placeholder="YouTube Link"  />
                          )}
                      </FormItem>
                      </Col> 
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <Form.Item label="Address Pin on map" >
                        {
                            getFieldDecorator('position', {
                                // validateTrigger: ['onChange', 'onBlur'],
                                // initialValue: {
                                //     lat:this.state.lat,
                                //     lng:this.state.lng
                                // },
                            //     rules: [
                            //                     {
                            // validator:(rule, value, cb)=>{
                            //     if(this.state.lat == ""){
                            //       cb('location required')
                            //     }
                            //     cb()
                            // }}
                            // ],
                            })(
                                <div>
                                <div  onClick={this.handleOpen} ><img src="https://cdn.pixabay.com/photo/2016/03/22/04/23/map-1272165__340.png" width="40" height="40"/></div>
                            <Modal
                            footer={null}
                             width='200'
                                title="Select your Location on the Map"
                                visible={this.state.visible}
                                onOk={this.handleOpen}
                                onCancel={this.handleCancel}
                                >  <MapComponent isMarkerShown getPosition={this.getPosition}  position={data} />

                                   
                                
                            </Modal>
                            </div>
                            )
                        }
                    </Form.Item>
                    { this.state.position !== undefined && this.state.locationSaved == null ? <p style={{color:'green'}}><Icon type="check"/> Location Updated</p> : this.state.locationSaved  && <p style={{color:'green'}}><Icon type="check"/> Location Saved</p>}
                    {/* {this.state.lat &&
                    <div style={{color:"green"}}><b>Location Updated...</b></div> 
                  } */}
                {this.state.locatioError && <h1 style={{color:'red'}}>Please select the Location Pin on Map</h1>}


                        </Col>

                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                        <Form.Item label="Banner">
                        {getFieldDecorator('banners', {
                      initialValue: setdata.banners!=undefined?setdata.banners:"--",
                      rules: [{
                        required: true,
                        message: "Banner are required"
                      }]
                    })(
                    <Upload
                      listType="picture-card"

                      onChange={this.siteHandleChange1}
                      fileList={this.state.fileList}
                    >
                    {this.state.fileList.length >= 5 ? null : uploadButton}
                    </Upload>
                    )}
                  </Form.Item> 



                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                        </Form> 
            </App>
        )
    }
}
const WrappedSetting = Form.create()(Setting);
// const CreateSettingcompres =  compose(
//     graphql(updateSiteSetting)
//   )(WrappedSetting)
export default withApollo(WrappedSetting)
