import gql from 'graphql-tag'

export default gql`

mutation deleteAnnouncement(
    
    $sortId:String!
    )

    {
        deleteAnnouncement( 
            sortId:$sortId
            )
            {
                sortId
            }
  

}`
