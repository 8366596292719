import React from 'react'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Divider, Popconfirm, Form, Input,message } from 'antd';
import Highlighter from 'react-highlight-words';
import { compose, graphql, withApollo } from 'react-apollo'

import moment from 'moment';
import getServiceCategories from '../query/getServiceCategories'
import App from '../App'
class ManageServices extends React.Component{
    constructor(props){
        super(props)
        this.state={
            merchantData:[],
            loading:false
        }
    }
    enterLoading = ()=>{
        this.setState({loading:true})
    }
    componentDidMount(){
      this.enterLoading()
      this.props.client.query({
          query:getServiceCategories,
          fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            // console.log("getServiceCategories",data)
            this.createDatasource(data.getServiceCategories.items)

             this.setState({
            //   merchantData: data.getServiceCategories.items,
              loading:false
            });
          })
          .catch(err => {
              console.log(`Error ${JSON.stringify(err)}`)
          })
      }
      createDatasource = (data) => {
        console.log("all data", data)
        let dataSource = data.map((m, i) => {
            //   console.log('mob number ==',m.consumerMob)

            return {
                serviceId: m.serviceId,
                serviceName: m.serviceName,
                enabled: m.enabled,
                photo: m.photo,
                NumSubCategories: m.serviceSubCategories.length,
                createdAt: m.createdAt,
                serviceSubCategories:m.serviceSubCategories
            }
        }
        )

        this.setState({
            merchantData: dataSource
        })
    }
      // getColumnSearchProps = dataIndex => ({
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters
      //   }) => (
      //     <div className="custom-filter-dropdown">
      //       <Input
      //         ref={node => {
      //           this.searchInput = node;
      //         }}
      //         placeholder={`Search here`}
      //         value={selectedKeys[0]}
      //         onChange={e =>
      //           setSelectedKeys(e.target.value ? [e.target.value] : [])
      //         }
      //         onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
      //         style={{ width: 188, marginBottom: 8, display: 'block' }}
      //       />
      //       <Button
      //         type="primary"
      //         onClick={() => this.handleSearch(selectedKeys, confirm)}
      //         icon="search"
      //         size="small"
      //         style={{ width: 90, marginRight: 8 }}
      //       >
      //         Search
      //       </Button>
      //       <Button
      //         onClick={() => this.handleReset(clearFilters)}
      //         size="small"
      //         style={{ width: 90 }}
      //       >
      //         Reset
      //       </Button>
      //     </div>
      //   ),
      //   filterIcon: filtered => (
      //     <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      //   ),
      //   onFilter: (value, record) => {
      //     if (dataIndex == 'upn') {
      //       return record[dataIndex]
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toLowerCase());
      //     } else {
      //       let a = dataIndex.split('.')[1].toString();
      //       return record.masterVariationDump[a]
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toLowerCase());
      //     }
      //   },
      //   onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      //   onFilterDropdownVisibleChange: visible => {
      //     if (visible) {
      //       setTimeout(() => this.searchInput.select());
      //     }
      //   },
      //   render: text => (
      //     <Highlighter
      //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //       searchWords={[this.state.searchText]}
      //       autoEscape
      //       textToHighlight={text.toString()}
      //     />
      //   )
      // });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
        });
      };
    

    render(){
    //   console.log("data ren",this.state.merchantData)
        const columns = [ 
               
            {
                title: 'Date',
                dataIndex: 'createdAt',
                key:'createdAt',
                width:200,
                sorter: (a, b) => {
                  return a.createdAt -  b.createdAt
                },
                render: (date) => {
                  var dateString = moment.unix(date).format("DD/MM/YYYY");
                  // console.log(date,dateString)
                  return(
  
                      <span>{dateString}</span>
                  )
              }
              },
  
              {
                title: 'Service Name',
                dataIndex: 'serviceName',
                key: 'fName',
                // ...this.getColumnSearchProps('fName'),
              },
              {
                title: 'Image',
                dataIndex: 'photo',
                width: 200,
                render: photo => {
                    // console.log("photo",photo);
                  return photo != null  ? (
                    <img
                      // src={'https://ppl-inida-dev.s3.us-east-2.amazonaws.com/'+ photo.key}
                      src={ `https://${photo.bucket}.s3.${photo.region}.amazonaws.com/${photo.key}`}
                      style={{ width: '70px' }}
                    />
                    
                  ) : (
                    ''
                  );
                }
              }, 
            //   {
            //     title: 'Last Name',
            //     dataIndex: 'lName',
            //     key: 'lName',
            //     // ...this.getColumnSearchProps('lName'),
            //   }, {
            //     title: 'Shop Name',
            //     dataIndex: 'businessName',
            //     key: 'businessName',
            //     // ...this.getColumnSearchProps('businessName'),
            //   },
              {
                title: 'Number of Sub Service',
                dataIndex: 'NumSubCategories',
                key: 'serviceSubCategories',
                // ...this.getColumnSearchProps('mob'),

              },
            //   {
            //     title: 'Email',
            //     dataIndex: 'email',
            //     key: 'email',
            //   },
            //   {
            //     title: 'Date',
            //     dataIndex: 'createdAt',
            //     key:'createdAt',
            //     sorter: (a, b) => {
            //       return a.createdAt -  b.createdAt
            //     },
            //     render: (date) => {
            //       var dateString = moment.unix(date).format("DD/MM/YYYY");
            //       return(
            //           <span>{dateString}</span>
            //       )
            //   }
            //   },
               {
                        title: 'Action',
                        key: 'action',
                        render: (data) => {
                                return (
                                    <span>
                                        <Link to={{pathname:"/sub_services",
                                            state:{merchantData:data}
                                        }}>
                                            <Button type="primary" icon="eye" style={{ cursor: "pointer",marginRight:"10px" }}/>
                                        </Link>
                                        <Link to={{pathname:"/edit_service",
                                            state:{merchantData:data}
                                        }}>
                                            <Button type="primary" icon="edit" style={{ cursor: "pointer",marginRight:"10px" }}/>
                                        </Link>
                                        
                                        {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.mob,data.userName)}>
                                            <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
                                        </Popconfirm> */}
                                    </span>
                                )
                            }
                        
                }
    
            ];
        return(
            <App header={"Manage Services"}>
            <Row gutter={16}>
                {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                    <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Breadcrumb.Item>Marchant List</Breadcrumb.Item>
                    </Breadcrumb>
                </Col> */}
                <div class="flex-container">
                    {/* <Link to={{ pathname :"/jmservice" }}>
                          <Button type="default" style={{ marginBottom: 16, marginRight: 5, marginLeft: 10, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                          Manage Service
                          </Button>
                      </Link> */}
                   
                    <Link to={{ pathname :"/create_services" }}>
                        <Button type="default" style={{ marginBottom: 16, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                        Create Services
                        </Button>
                    </Link>
                </div>
                {/* <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Merchant</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col> */}
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                        {/* <Link to="/">
                            <Link to="/createMarchant">
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Merchant
                                </Button>
                            </Link>
                        </Link> */}
                    </Col>
                </Row>
                <Table columns={columns} loading={this.state.loading} dataSource={this.state.merchantData} />

            </App>
        );
    }
}


const wrapMarchant = compose(
  withApollo,
  // graphql(GetMerchant,{
  //     options: props => ({
  //         fetchPolicy:"network-only"
  //     })

  // }),
  graphql(getServiceCategories,{
      name:'getServiceCategories'
  })

)(ManageServices)
export default wrapMarchant