import gql from 'graphql-tag'

export default gql`
mutation createMerchant(
  $mob: String!
  $email: String
  $fName: String!
  $lName: String!
  $businessName: String!
  $services: [String!]
  $estDate: String
  $searchFiled: String
  $saloonType: SALOON_TYPE
  $gstNo: String
  $enabled: Boolean
  $address: GenericAddressInput
){
  createMerchant(input:{
    mob: $mob
    email: $email
    fName: $fName
    lName: $lName
    businessName: $businessName
    services: $services
    estDate: $estDate
    searchFiled: $searchFiled
    saloonType: $saloonType
    gstNo: $gstNo
    enabled: $enabled
    address: $address
  }){
    userType
    mob
    email
    fName
    lName
    businessName
    services
    estDate
    searchFiled
    saloonType
    gstNo
    enabled
    address{
      addressLineOne
      addressLineTwo
      city
      state
      zip
      country
    }
  }
}

`