import React from 'react'
import { Route, BrowserRouter as Router } from "react-router-dom";
import Userlist from './userlist'
import Marchant from './manageMarchant'
import MarchantRe from './marchantReq'
import CreateMerchant from './createMarchant'
import ManageAppointment from './manageAppointments'
import Banner from './banner'
import CustomerEmail from './CustomerEmail'
import CustomerReviews from './CustomerReviews'
import EditMerchant from './edit_marchant'
import AnnouncementList from './AnnouncementList'
import CreateAnnouncement from './create-announcement'
import Setting from './setting'
import UpdateAnnouncement from './update-announcement'
import CreateServices from './createServices'
import ManageServices from './manageServices'
import Sub_servicelist from './sub_servicelist'
import Edit_service from './edit_service'
import MarchantreqEdit from './marchant_req_edit'
import App from '../App'
const Routers =()=>(
    <Router>
        <React.Fragment>
            <Route exact path="/" component={MarchantRe} />
            <Route path="/userlist" component={Userlist} />
            <Route path="/merchant" component={Marchant} />
            <Route path="/merchantRe" component={MarchantRe} />
            <Route path="/createMarchant" component={CreateMerchant} />
            <Route path="/manageAppointment" component={ManageAppointment} />
            <Route path="/homepage" component={Banner} />
            <Route path="/customeremail" component={CustomerEmail} />
            <Route path="/customerreviews" component={CustomerReviews} />
            <Route path="/editmerchant" component={EditMerchant} />
            <Route path="/announcements" component={AnnouncementList} />
            <Route path="/create_Announcement" component={CreateAnnouncement} />
            <Route path="/setting" component={Setting} />
            <Route path="/update_announcment" component={UpdateAnnouncement} />
            <Route path="/create_services" component={CreateServices} />
            <Route path="/manage_services" component={ManageServices} />
            <Route path="/sub_services" component={Sub_servicelist} />
            <Route path="/edit_service" component={Edit_service} />
            <Route path="/merchantreqEdit" component={MarchantreqEdit} />

        </React.Fragment>
    </Router>
);
export default Routers