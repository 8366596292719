import gql from 'graphql-tag'

export default gql`
mutation createServiceCategory(
  $serviceId: String!
  $slugServiceName: String!
  $serviceName: String!
  $type: String
  $searchFiled: String
  $enabled: Boolean!
  $photo: S3ObjectInput
){
  createServiceCategory(input:{
    serviceId: $serviceId
    slugServiceName: $slugServiceName
    serviceName: $serviceName
    enabled: $enabled
    type: $type
    searchFiled: $searchFiled
    photo: $photo
  }){
    serviceId
    slugServiceName
    serviceName
    enabled
    type
    searchFiled
    createdAt
    photo{
      bucket
      region
      key
    }
  }
}
`