import React from 'react'
import { Row,Col, Breadcrumb, Button, Icon, Table, Spin ,Tabs,Input} from 'antd';
import { Query, graphql, compose, withApollo } from 'react-apollo';
import getAppointments from '../query/getAppointments'
let skip = 0
const limit = 80
let skipd = 0
const limitd = 80
class RejectedAppointment extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data: [],
            dataSource: [],
            visible: false,
            pagination: {},
        }
    }
    componentDidMount(){
        this.enterLoading()
        this.props.client.query({
          query: getAppointments,
          variables:{
            limits:limit,
            skips: skip,
            status: "REJECTED"
          }
        }).then(({data})=>{
          // console.log("apt",data.getAppointments)
          this.createDatasource(data.getAppointments)
          const pagination = { ...this.state.pagination };
          this.setState({
            data: data.getAppointments,
            loading:false
          });
          // Read total count from server
          // pagination.total = data.totalCount;
          // pagination.total = data.getAppointments.length + skip;
        // if(data.getAppointments){
        //     let values = this.state.visitorsData.concat(data.getAppointments)
        //       this.setState({visitorsData:values,
        //         loading: false,
        //         pagination,
        //       })
        // }
        }).catch((err)=>{
          console.log(err)
        })
      }
      createDatasource=(data)=>{
        let dataSource = data.map((m,i)=>{
          return{
            key:i,
            id: m.id,
            apptDate: m.apptDate,
            timeSlot: m.timeSlot,
            merchantMob:m.merchantMob,
            services: m.services && m.services!=null ? m.services.join(" , "): m.services,
            merchantId: m.merchantId,
            consumerName:m.consumerName,
            consumerMob:m.consumerMob,
            cityc:m.city,
            pin:m.zip,

          }
        })
  
        this.setState({
          dataSource:dataSource
        })
    }
    enterLoading=()=>{
        this.setState({
            loading: !this.state.loading
        })
      }
    handleTableChange = (pagination, filters, sorter) => {
        skip = skip + limit 
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
          loading: true,
        });
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
        this.fetch();
      };
    
    render(){
        const columns = [
            
            {
                title: 'Date',
                width: 125,
                dataIndex: 'apptDate',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              }, {
              title: 'Time Slot',
              dataIndex: 'timeSlot',
              width: 125,
              key: '',
              // ...this.getColumnSearchProps('fName'),
            }, {
              title: 'Services',
              width: 200,
              dataIndex: 'services',
              key: '',
              // ...this.getColumnSearchProps('lName'),
          
            },{
                title: 'Merchant',
                className: 'marchant',
                children: [
                  {
                    title: 'Name',
                    dataIndex: 'merchantId',
                    key: '',
                    width: 150
                  },
                  {
                    title: 'Mobile',
                   
                        dataIndex: 'merchantMob',
                        key: '',
                        width: 150
                      }],
                    },
                    {
                        title: 'Customer',
                        align: 'center',
                        children: [
                          {
                            title: 'Name',
                            dataIndex: 'consumerName',
                            key: '',
                            width: 100
                          },
                          {
                            title: 'Mobile',
                           
                                dataIndex: 'consumerMob',
                                key: '',
                                width: 100
                              }],
                            },
             ];
        return(
            <Table dataSource={this.state.dataSource} columns={columns} bordered  onChange={this.handleTableChange}/>
        )
    }

}
const wrapRejectedAppointment = compose(
    withApollo,
    graphql(getAppointments,{
        name:'getAppointments'
    })
  
  )(RejectedAppointment)
export default wrapRejectedAppointment