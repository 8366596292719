import React from 'react'
import { Select, Form, Input, Button, InputNumber, Upload, Modal, Row, Col, Cascader, message,Breadcrumb, Menu, Dropdown, Icon} from 'antd';
import App from '../App'
import AppSy from '../configs/AppSy';

import { graphql, compose,withApollo} from 'react-apollo';

import updateAnnouncement from '../mutation/updateAnnouncement'
import { Link } from 'react-router-dom'
import slugify from 'slugify';
import _ from 'lodash'
import uuidv1 from 'uuid/v1';
const FormItem = Form.Item;
const bucket = AppSy.bucket ? AppSy.bucket : 'saloon-app-dev';
const region = AppSy.region ? AppSy.region : 'ap-south-1';
const { TextArea } = Input;

class UpdateAnnouncement extends React.Component{
    constructor(props){

        super(props)
        // console.log("ssssssssss",props)
        this.state={
            iconImg: this.props.location.state.merchantData.icon != null ? {
                key: this.props.location.state.merchantData.icon.key,
                uid: this.props.location.state.merchantData.icon.key,
                bucket: this.props.location.state.merchantData.icon.bucket,
                region: this.props.location.state.merchantData.icon.region,
                status: 'done',
                url: `https://${this.props.location.state.merchantData.icon.bucket}.s3.${this.props.location.state.merchantData.icon.region}.amazonaws.com/${this.props.location.state.merchantData.icon.key}`
            }: {},
            removedIconImg: false,
            loading: false,
            
        }
    }

    componentDidMount(){
        let image=this.props.location.state.merchantData.icon
        let img1 = image != null ? {
            key: this.props.location.state.merchantData.icon.key,
            uid: this.props.location.state.merchantData.icon.key,
            bucket: this.props.location.state.merchantData.icon.bucket,
            region: this.props.location.state.merchantData.icon.region,
            status: 'done',
            url: `https://${this.props.location.state.merchantData.icon.bucket}.s3.${this.props.location.state.merchantData.icon.region}.amazonaws.com/${this.props.location.state.merchantData.icon.key}`
        }:{}
              this.setState({
                iconImg:img1,
              })
    }
    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
                return{
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else{
                return{
                    // iconImg: {},
                    removedIconImg: false
                }
            }
        });
    }

    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return true;
    }
    
    handleCancelBtn = ()=>{
        this.props.history.push('/announcements');
    }

    handleCancel = () => this.setState({ previewVisible: false })

    
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }
   
    
    handleBannerChange = ({ fileList }) => this.setState({ fileList })

    enterLoading = () => {
      this.setState({ loading: true });
    }

    messageClose = () => {
      this.props.history.push('/announcements');
    // this.setState({loading:false})
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            // console.log('Received values of form: ', values);
            if(Object.keys(this.state.iconImg).length > 0 && !values.icon.hasOwnProperty('key')){
                values.iconImg = {
                    bucket: bucket,
                    region: region,
                    localUri:this.state.iconImg.originFileObj,
                    key:`Announcement/${values.name}/${this.state.iconImg.name}`,
                    mimeType:this.state.iconImg.type
                } 
              }else{ 
                values.iconImg = {
                bucket: bucket,
                region: region,
                // localUri:this.state.iconImg.originFileObj,
                // key:`Announcement/${values.serviceName}/${this.state.iconImg.name}`,
                key:this.state.iconImg.key
                // mimeType:this.state.iconImg.
            };
        }
        // console.log("iconnnnnn",values.iconImg)
                            this.props.client.mutate({
                                mutation: updateAnnouncement,
                                variables: {
                                    mId: this.props.location.state.merchantData.mId,
                                    sortId: this.props.location.state.merchantData.sortId,
                                    name: values.name,
                                    slugName: "test",
                                    title: values.title,
                                    description: values.description,
                                    link: values.link,
                                    redirectTo: values.redirectTo ? values.redirectTo : null,
                                    enabled: "true",
                                    icon:values.iconImg && values.iconImg.key!=undefined?values.iconImg:null,
                                }
                            }).then(({data}) => {
                                // console.log(data)
                                if(data.updateAnnouncement){
                                message.success('Announcement Update Successfully');
                                
                                }
                                })
          }
        });
      };

    render(){
        let merchantData = this.props.location.state.merchantData
        // console.log("anno",this.merchantData);
        const { getFieldDecorator } = this.props.form;
        const { previewVisible, previewImage, iconImg } = this.state;
        // const {iconImg}=this.state;

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">
                    Upload
                </div>
            </div>
        );
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return(
            <App header={"Update Announcement"}>
               <Breadcrumb style={{marginBottom:"20px"}}>
            <Link to="/">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Link to="/announcements">
                <Breadcrumb.Item>Announcements</Breadcrumb.Item>
            </Link>
                <Breadcrumb.Item>Add New Announcement</Breadcrumb.Item>
            </Breadcrumb>
                <div>
                    <Form layout='vertical' onSubmit={this.handleSubmit}>
                      
                        <FormItem label="Name" {...formItemLayout}>
                            {getFieldDecorator('name', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                initialValue:merchantData.name,
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input name",
                                }],
                            })(
                                <Input placeholder="Name" />
                            )}
                        </FormItem>
                        <FormItem label="Title" {...formItemLayout}>
                            {getFieldDecorator('title', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                initialValue:merchantData.title,
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input title",
                                }],
                            })(
                                <Input placeholder="Title" />
                            )}
                        </FormItem>
                        
                        <FormItem label="Description" {...formItemLayout}>
                            {getFieldDecorator('description', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                initialValue:merchantData.description,
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input description",
                                }],
                            })(
                                <TextArea onChange={this.onChangeText} placeholder="Description" />
                            )}
                        </FormItem>

                        <FormItem label="Link" {...formItemLayout} extra="Announcement will open on this link.">
                            {getFieldDecorator('link', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                initialValue:merchantData.link,
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input link",
                                }],                                
                            })(
                                <Input placeholder="Link"  />
                            )}
                        </FormItem>

                        <FormItem label="Redirect to" {...formItemLayout} extra="On click of the announcement it will open this page.">
                            {getFieldDecorator('redirectTo', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                initialValue:merchantData.redirectTo,
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <Input placeholder="Redirect to"  />
                            )}
                        </FormItem>

                        <FormItem label="Icon" {...formItemLayout} extra="Add square image.">
                            {getFieldDecorator('icon', {
                                // validateTrigger: ['onChange','onBlur'],
                                initialValue:merchantData.icon,
                                rules: [{
                                    required: false
                                }],
                            })(
                                <Upload
                                    
                                 listType="picture-card"
                                 defaultFileList={Object.keys(this.state.iconImg).length != 0 ? [this.state.iconImg] : []}
                                 data={this.state.iconImg}
                                 onPreview={this.handlePreview}
                                 onChange={this.handleMainImage}
                                 onRemove={this.handleMainImageRemove}
                       
                                   >
                               {Object.keys(iconImg).length !=0 ? null : uploadButton}
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </FormItem>
                        {/* <FormItem label="Delay in seconds" {...formItemLayout} extra="Announcement will open after the given seconds.">
                            {getFieldDecorator('delay', {
                                initialValue:1,
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <InputNumber min={0} max={60} placeholder="Delay in seconds" style={{width:"100%"}} />
                            )}
                        </FormItem> */}


                        <Button type="primary"  style={{background:"#389e0d", color:"#fff"}} htmlType="submit" >Submit</Button>
                        <Button type="default" style={{background:"#f44336", color:"#fff",marginLeft:"20px"}} onClick={this.handleCancelBtn}>Cancel</Button>
                    </Form>
                </div>
            </App>
        );
    }
}

const WrappedAcc = Form.create()(UpdateAnnouncement);

export default withApollo(WrappedAcc)