import gql from 'graphql-tag'
export default gql`
mutation updateServiceCategory(
  $serviceId: String!
  $slugServiceName: String!
  $serviceName: String!
  $enabled: Boolean!
  $type: String
  $photo: S3ObjectInput
){
  updateServiceCategory(input:{
    serviceId: $serviceId
    slugServiceName: $slugServiceName
    serviceName: $serviceName
    enabled: $enabled
    photo: $photo
    type: $type
  }){
    serviceId
    slugServiceName
    serviceName
    enabled
    type
    createdAt
    photo{
      bucket
      region
      key
    }
  }
}
`