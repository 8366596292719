import React from 'react'
import { Row,Col, Breadcrumb, Button, Icon, Table, Spin ,Tabs,Input} from 'antd';
import { Query, graphql, compose, withApollo } from 'react-apollo';
import App from '../App'
import getAppointments from '../query/getAppointments'
import { Link } from "react-router-dom";
import ApprovedAppointment from './Approved_Appointment'
import RejectedAppointment from './Rejected_Appointment'
let skip = 0
const limit = 80
let skipd = 0
const limitd = 80
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
class ManageAppointment extends React.Component{
    constructor(props){
        super(props)
        this.state={
            data: [],
            dataSource: [],
            visible: false,
            pagination: {},
            loading:false
        }
      }
      componentDidMount(){
        this.enterLoading()
        this.props.client.query({
          query: getAppointments,
          variables:{
            limits:limit,
            skips: skip,
            status: "NEW"
          }
        }).then(({data})=>{
          console.log("apt",data.getAppointments)
          this.createDatasource(data.getAppointments)
          const pagination = { ...this.state.pagination };
          this.setState({
            data: data.getAppointments,
            loading:false
          });
          // Read total count from server
          // pagination.total = data.totalCount;
          // pagination.total = data.getAppointments.length + skip;
        // if(data.getAppointments){
        //     let values = this.state.visitorsData.concat(data.getAppointments)
        //       this.setState({visitorsData:values,
        //         loading: false,
        //         pagination,
        //       })
        // }
        }).catch((err)=>{
          console.log(err)
        })
      }
    
      enterLoading=()=>{
        this.setState({
            loading: !this.state.loading
        })
      }
      showModal = (data) => {
          this.setState({
            visible: true,
           m_data:data
          });
        };
        handleOk = (e) => {
          this.setState({
            visible: false,
          });
        }
        handleCancel = (e) => {
          this.setState({
            visible: false,
          });
        }

      createDatasource=(data)=>{
        let dataSource = data.map((m,i)=>{
        //   console.log('mob number ==',m.consumerMob)
        //  console.log("all data",data)
          return{
            key:i,
            id: m.id,
            apptDate: m.apptDate,
            timeSlot: m.timeSlot,
            merchantMob:m.merchantMob,
            services: m.services && m.services!=null ? m.services.join(" , "): m.services,
            merchantId: m.merchantInfo.businessName,
            consumerName:m.consumerName,
            consumerMob:m.consumerMob,
            cityc:m.city,
            pin:m.zip,

          }
        })
  
        this.setState({
          dataSource:dataSource
        })
    }
    handleTableChange = (pagination, filters, sorter) => {
      skip = skip + limit 
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
        loading: true,
      });
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
      this.fetch();
    };
   
  
    render(){
      // console.log("dataSource",dataSource);
      // console.log(this.state)
        const columns = [
            
            {
                title: 'Date',
                width: 125,
                dataIndex: 'apptDate',
                key: '',
                // ...this.getColumnSearchProps('mob'),
              }, {
              title: 'Time Slot',
              dataIndex: 'timeSlot',
              width: 125,
              key: '',
              // ...this.getColumnSearchProps('fName'),
            }, {
              title: 'Services',
              width: 200,
              dataIndex: 'services',
              key: '',
              // ...this.getColumnSearchProps('lName'),
          
            },{
                title: 'Merchant',
                className: 'marchant',
                children: [
                  {
                    title: 'Name',
                    dataIndex: 'merchantId',
                    key: '',
                    width: 150
                  },
                  {
                    title: 'Mobile',
                   
                        dataIndex: 'merchantMob',
                        key: '',
                        width: 150
                      }],
                    },
                    {
                        title: 'Customer',
                        align: 'center',
                        children: [
                          {
                            title: 'Name',
                            dataIndex: 'consumerName',
                            key: '',
                            width: 100
                          },
                          {
                            title: 'Mobile',
                           
                                dataIndex: 'consumerMob',
                                key: '',
                                width: 100
                              }],
                            },
             ];


        return(
          <App header={"Appointments"}>
             <Row gutter={16}>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Appointment List</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
             </Row>
                <Tabs onChange={callback} type="card">
                  <TabPane tab="New" key="1">
                  {this.state.loading ? <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                  </div> : <Table dataSource={this.state.dataSource} pagination={true} columns={columns} bordered  />}
                  </TabPane>
                  <TabPane tab="Approved" key="2" >
                  {this.state.loading ? <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                  </div> : <ApprovedAppointment />}
                  </TabPane>
                  <TabPane tab="Rejected" key="3">
                  {this.state.loading ? <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                  </div> : <RejectedAppointment />}
                  </TabPane>
                  {/* <TabPane tab="Completed " key="4">
                  {this.state.loading ? <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                  </div> : <RejectedAppointment />}
                  </TabPane> */}
                </Tabs>
            
          
          </App>
        );
}
}
const wrapManageAppointment = compose(
  withApollo,
  // graphql(GetMerchant,{
  //     options: props => ({
  //         fetchPolicy:"network-only"
  //     })

  // }),
  graphql(getAppointments,{
      name:'getAppointments'
  })

)(ManageAppointment)
export default wrapManageAppointment