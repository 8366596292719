import React from 'react'
import { Breadcrumb, Button, Col, Icon, Input, Row, Spin, Switch, Table } from 'antd'
import Highlighter from 'react-highlight-words';
import moment from 'moment'

import App from '../App'
class CustomerReviews extends React.Component{
    constructor(props){
        super(props)
        this.state={
            nextToken : '',
            dataSource : [],
            loading:false,
            hasMore:true
        }
    }
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => { this.searchInput = node; }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
    })
    
    render(){
        const columns=[
            {
                title: 'Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: (a, b) => {
                    return a.createdAt -  b.createdAt
                },
                render:(data)=>{
                    var dateString = moment.unix(data).format("DD/MM/YYYY");
                    return(
                        <span>{dateString}</span>
                    )
                }
            },
            // {
            //     title: 'Sku Code',
            //     dataIndex: 'skuCode',
            //     key: 'skuCode',
            //     ...this.getColumnSearchProps('skuCode'),
            // },
            {
                title: 'Service Name',
                dataIndex: 'displayName',
                key: 'displayName',
                ...this.getColumnSearchProps('displayName'),
            },
            {
                title: 'Salon Name',
                dataIndex: 'compName',
                key: 'compName',
                ...this.getColumnSearchProps('compName'),
            },
            {
                title: 'Customer Email',
                dataIndex: 'custId',
                key: 'custId',
            },
            {
                title: 'Rating',
                dataIndex: 'rate',
                key: 'rate',
                ...this.getColumnSearchProps('rate'),
            },
            {
                title: 'Comments',
                dataIndex: 'comment',
                key: 'comment',
                render:(data)=>{
                    return(
                        <div>
                            {data == null ? <span> -- </span> :  <span>{data}</span> }
                        </div>
                    )
                }
            },
            {
                title: 'Action',
                key:'action',
                onFilter: (value, record) =>{
                    return  record.enabled.toString() == value
                   },
                  
                   filters : [
                       {text :"Active",value:true},
                       {text:"InActive", value:false},
                   ],
                render:(data,i)=>{
                    return(
                         <div style={{ marginTop: 10 }}>
                            <Switch onChange={(e)=>this.submitMut(e,data,data.id)} checked={data.enabled} />
                            {/* <Icon type="bulb" size="large" theme="filled" style={{ marginLeft:"20px",fontSize: '20px', color: data.enabled ? 'green' : 'red'}} /> */}
                        </div>
                    )

                }

            }
        ]
        return(
            <App header={"Customer Reviews"}>
                <Table columns={columns} pagination={false} dataSource={this.state.dataSource}/>

            </App>
        )
    }
}
export default CustomerReviews