import React from 'react'
import { Link } from 'react-router-dom';
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Modal, Popconfirm, Form, Input,message } from 'antd';
import Highlighter from 'react-highlight-words';
import { compose, graphql, withApollo } from 'react-apollo'
import moment from 'moment';
import getMerchants from '../query/getMerchants'
import actionOnMerchantRequest from '../mutation/actionOnMerchantRequest'
import App from '../App'
import ViModel from './view-modal'
const limit = 40
class MarchantRe extends React.Component{
    constructor(props){
        super(props)
        this.state={
            visible: false,
            merchantData:[],
            loading:false,
            modal:{},
            nextToken: "",
            pagination: {},
        }
    }
    enterLoading = ()=>{
        this.setState({loading:true})
    }
    showModal = (data) => {
      console.log("asaaa",data)
        this.setState({
          visible: true,
          modal:data
        //   state:{merchantData:data}
        //  visible1:true
        });
      };
    componentDidMount(){
      this.fetch();
      }
      handleTableChange = (pagination, filters, sorter) => {
        // skip = skip + limit 
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
          loading: true,
          search:false
        });
        if (this.state.nextToken == null) {
              this.setState({
                hasMore: false,
                onSearch: false,
                loading: false,
                search:false
         });
         return;
        }
        this.fetch({
          results: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
        // this.fetch();
      };
    
      fetch = (params = {}) => {
        this.setState({ loading: true });
        this.props.client
          .query({
            query: getMerchants,
            variables: {
              status: "NEW",
              nextToken: this.state.nextToken,
              limit: limit
            },
            fetchPolicy:"network-only"
          }).then(({ data }) => {
            const dgf = data.getMerchants.items
          const pagination = { ...this.state.pagination };
          const token =data.getMerchants.nextToken
         let all = this.state.merchantData.concat(dgf) 
          this.setState({
            merchantData: all,
            nextToken: token,
            loading: false,
            pagination,
          });
        })
    
        // if(data.getBasicProductsBySiteIdByupdatedAt){
        //     let values = this.state.dataSource.concat(data.getBasicProductsBySiteIdByupdatedAt)
        //      this.setState({dataSource:values,
        //                     loading: false,
        //                     pagination,
        
        //     })
        // }
        };
      // getColumnSearchProps = dataIndex => ({
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters
      //   }) => (
      //     <div className="custom-filter-dropdown">
      //       <Input
      //         ref={node => {
      //           this.searchInput = node;
      //         }}
      //         placeholder={`Search here`}
      //         value={selectedKeys[0]}
      //         onChange={e =>
      //           setSelectedKeys(e.target.value ? [e.target.value] : [])
      //         }
      //         onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
      //         style={{ width: 188, marginBottom: 8, display: 'block' }}
      //       />
      //       <Button
      //         type="primary"
      //         onClick={() => this.handleSearch(selectedKeys, confirm)}
      //         icon="search"
      //         size="small"
      //         style={{ width: 90, marginRight: 8 }}
      //       >
      //         Search
      //       </Button>
      //       <Button
      //         onClick={() => this.handleReset(clearFilters)}
      //         size="small"
      //         style={{ width: 90 }}
      //       >
      //         Reset
      //       </Button>
      //     </div>
      //   ),
      //   filterIcon: filtered => (
      //     <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      //   ),
      //   onFilter: (value, record) => {
      //     if (dataIndex == 'upn') {
      //       return record[dataIndex]
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toLowerCase());
      //     } else {
      //       let a = dataIndex.split('.')[1].toString();
      //       return record.masterVariationDump[a]
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toLowerCase());
      //     }
      //   },
      //   onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      //   onFilterDropdownVisibleChange: visible => {
      //     if (visible) {
      //       setTimeout(() => this.searchInput.select());
      //     }
      //   },
      //   render: text => (
      //     <Highlighter
      //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //       searchWords={[this.state.searchText]}
      //       autoEscape
      //       textToHighlight={text.toString()}
      //     />
      //   )
      // });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({
          searchText: '',
          nextToken: '',
          productList: [],
          loading: false,
          hasMore: true,
          search:false
          
        });
      };
      handleDelete =(record,ref)=> {
        this.props.client.mutate({
          mutation: actionOnMerchantRequest,
          variables:{
            mob: record,
            status: "APPROVED",
            refId: ref
          }
        }).then(({data})=>{
          if(data.actionOnMerchantRequest){
            let arr = this.state.merchantData;
            let i = arr.findIndex(x => x.mob == record)
            arr.splice(i,1)
            this.setState({
                merchantData:arr
            })
            message.success("Merchant Approved successfully")
            
          }
      })
      .catch(err => {
        console.log(`Error ${JSON.stringify(err)}`)
        message.error("Mobile Number Already Registered")
    })

     }
     handleReject =(record,ref)=> {
    this.props.client.mutate({
      mutation: actionOnMerchantRequest,
      variables:{
        mob: record,
        status: "REJECTED",
        refId: ref
      }
    }).then(({data})=>{
      if(data.actionOnMerchantRequest){
        let arr = this.state.merchantData;
        arr.splice(record.key,1)
        this.setState({
            merchantData:arr
        })
        message.success("Merchant deleted successfully")
        
      }
  })
  .catch(err => {
    console.log(`Error ${JSON.stringify(err)}`)
    // message.error("Mobile Number Already Registered")
})
 }
    //  renderBrokerUserInfo = () => {
    //     // console.log(data)
    //     return (
    //       <EditMerchant />
          
    //     )
    //   }
    handleOk = () => {
        this.setState({
          visible: false,
        });
      }
      handleCancel = () => {
        this.setState({
          visible: false,
        });
      }
    

    render(){
      console.log("data ren",this.state.merchantData)
        const columns = [ 
               
              // {
              //   title: 'User Name (Seller Login)',
              //   dataIndex: 'userName',
              //   key: 'userName',
              //   ...this.getColumnSearchProps('userName'),
              // },
              {
                title: 'First Name',
                dataIndex: 'fName',
                key: 'fName',
                // ...this.getColumnSearchProps('fName'),
              },
              {
                title: 'Last Name',
                dataIndex: 'lName',
                key: 'lName',
                // ...this.getColumnSearchProps('lName'),
              }, {
                title: 'Shop Name',
                dataIndex: 'businessName',
                key: 'businessName',
                // ...this.getColumnSearchProps('businessName'),
              },
              {
                title: 'Mobile Number',
                dataIndex: 'mob',
                key: 'mob',
                // ...this.getColumnSearchProps('mob'),

              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Date',
                dataIndex: 'createdAt',
                key:'createdAt',
                sorter: (a, b) => {
                  return a.createdAt - b.createdAt
                },
                render: (date) => {
                  var dateString = moment.unix(date).format("DD/MM/YYYY");
                  return(
                      <span>{dateString}</span>
                  )
              }
              },
          {
            title: 'Action',
            key: 'action',
            render: (data) => {
              console.log("selected data", data.mob)
              return (
                <span>
                  <Button type="primary" icon="eye" style={{ cursor: "pointer", marginRight: "5px" }} onClick={() => { this.showModal(data) }} />
                  <Modal
                    title="Merchant Details"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={"50%"}
                    footer={false}
                  >
                    <ViModel d={this.state.modal} />
                    {/* {this.renderBrokerUserInfo()} */}
                  </Modal>
                  <Link to={{
                    pathname: "/merchantreqEdit",
                    state: { merchantData: data }
                  }}>
                    <Button type="primary" icon="edit" style={{ cursor: "pointer", marginRight: "5px" }} />
                  </Link>
                  <Popconfirm title="Sure to Approval?" onConfirm={() => this.handleDelete(data.mob, data.refId)}>
                    <Button icon="check" type="primary" style={{ marginLeft: "5px", ursor: "pointer", background: "##f44336 " }} />
                  </Popconfirm>
                  <Popconfirm title="Sure to Reject?" onConfirm={() => this.handleReject(data.mob, data.refId)}>
                    <Button icon="close" type="primary" style={{ marginLeft: "5px", ursor: "pointer", background: "##f44336 " }} />
                  </Popconfirm>
                </span>
              )
            }

          }
    
            ];
        return(
            <App header={"Manage Merchant Request"}>
            <Row gutter={16}>
                {/* <Col  xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                    <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Breadcrumb.Item>Marchant List</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <div class="flex-container">
                    <Link to={{ pathname :"/jmservice" }}>
                          <Button type="default" style={{ marginBottom: 16, marginRight: 5, marginLeft: 10, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                          Manage Service
                          </Button>
                      </Link>
                   
                    <Link to={{ pathname :"/createMarchant" }}>
                        <Button type="default" style={{ marginBottom: 16, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                        Create Marchant
                        </Button>
                    </Link>
                </div> */}
                <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Merchant Request</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    {/* <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                        <Link to="/">
                            <Link to="/createMarchant">
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Merchant 
                                </Button>
                            </Link>
                        </Link>
                    </Col> */}
                </Row>
                <Table columns={columns} loading={this.state.loading} dataSource={this.state.merchantData} onChange={this.handleTableChange} pagination={this.state.pagination}/>

            </App>
        );
    }
}


// const wrapMarchant = compose(
//   withApollo,

//   graphql(getMerchants,{
//       name:'getMerchants'
//   })

// )(MarchantRe)
export default withApollo(MarchantRe)