import gql from 'graphql-tag'

export default gql`
mutation updateSiteSetting(
    $companyName: String
    $whatsAppNum: String
    $contactEmail: String
    $address: GenericAddressInput
    $location: LatLngInput
    $facebook: String
    $instagram: String
    $youtube: String
    $banners: [S3ObjectInput!]
  ){
    updateSiteSetting(input:{
      companyName: $companyName
      whatsAppNum: $whatsAppNum
      contactEmail:$contactEmail
      address: $address
      location: $location
      facebook: $facebook
      instagram: $instagram
      youtube: $youtube
      banners: $banners
    }){
      mId
      sortId
      companyName
      whatsAppNum
      contactEmail
      facebook
      instagram
      youtube
      banners{
        bucket
        region
        key
      }
      location{
        lat
        lng
      }
      address{
        addressLineOne
        addressLineTwo
        city
        state
        zip
        country
      }
    }
  }
  `
  