import React from 'react'
import { Button, Col, Icon, Form, Input, Row, Select, Table, message,Modal} from 'antd'
import {compose, graphql, withApollo} from 'react-apollo';
import App from '../App'
import updateMerchant from '../mutation/updateMerchant'
import getServiceCategories from '../query/getServiceCategories'
// import getMerchants from '../query/getMerchants'
import MapComponent from './mapSearchBox'

const FormItem = Form.Item
const Option = Select.Option;
const enabled = [{key:0, label:"ACTIVE", value:true}, {key:1, label:"INACTIVE", value:false}]
const salontype = [{key:0, label:"Mens", value:"MENS"},{key:1, label:"Women", value:"WOMEN"},{key:2, label:"Unisex", value:"UNISEX"},]
const country = [
    { value: 'IND', label: 'India' },
    { value: 'USA', label: 'USA' },
    { value: 'UK', label: 'UK '}
]
const columns = [{
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
}, {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
}, {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
}, {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
}, {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
}]

const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]
class EditMerchant extends React.Component{
    constructor(props){
    super(props)
    // console.log("cons",props)

    this.state={
        loading:false,
        visible:false,
            selectedZipcode: ['all'],
            selectedCity: '',
            selectedState: '',
            selectedCountry: '',
            countryData: [],
            stateData: [],
            cityData: [],
            zipData: ['all'],
            selectedLocations: [],
            locationError: false,
            dataSource:[],
            name:'',
            categoryData: [],
            lat:'',
            lng:'',
            position: this.props.location.state.merchantData != undefined &&
            this.props.location.state.merchantData !=null && 
            this.props.location.state.merchantData.loc != null ? 
            this.props.location.state.merchantData.loc : undefined,
            locationSaved:null,
            locatioError:false
    }
}

componentDidMount(){
      this.props.client.query({
        query: getServiceCategories,
        fetchPolicy: 'network-only' 
      }).then(({data})=>{
        // console.log("locationdatatttt",data)
        this.setState({
          categoryData:data.getServiceCategories.items,
        //   position:this.props.location.state.merchantData.loc
        })
      }).catch((err)=>{
        console.log(err)
      })
    }


    enterLoading = ()=>{
      this.setState({loading:true})
  }
handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
        if(this.state.position==undefined){
            this.setState({
        locatioError:true,
            })
            return false
        }
        else{
            this.setState({
                locatioError:false,
                    })
        }
      if (!err) {
       this.enterLoading()
        console.log('Received values of form: ', values.gstNo);
        // return false;
                        this.props.client.mutate({
                            mutation: updateMerchant,
                            variables: {
                                userType:values.salontype,
                                mob:values.mob,
                                email:values.email,
                                fName:values.fName,
                                lName:values.lName,
                                businessName:values.businessName, 
                                services: values.services,
                                saloonType:values.salontype,
                                gstNo:values.gstNo?values.gstNo: null,
                                enabled:values.status,
                                loc:{
                                    lat:this.state.position!=undefined && this.state.position!=null ? this.state.position.lat:undefined,
                                    lng:this.state.position!=undefined && this.state.position!=null ? this.state.position.lng:undefined,
                                  },
                                address:{
                                addressLineOne:values.addressLineOne,
                                addressLineTwo:values.addressLineTwo ? values.addressLineTwo : null,
                                city:values.city,
                                state:values.state,
                                zip:values.zip,
                                country:"IND",
                                },
                                // refetchQueries:[{
                                //     query:getMerchants
                                // }]
                            }
                        }).then(({data}) => {
                            // console.log("susses",data)
                            // message.success('Merchant Updated Successfully...');
                            if(data.updateMerchant){
                                // console.log("update",data.updateMerchant)
                                this.setState({
                                    loading:false
                                })
                            message.success('Merchant Updated Successfully',this.props.history.push('/merchant'))

                            }
                            }).catch(res => {
                                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
                                message.error('Something went wrong', 3);
                                
                            });
      }else{
          console.log(err)
      }
    });
  };
  handleclick =()=>{
    this.props.history.push('/merchant')
}

handleOpen = () => this.setState({ visible: !this.state.visible })
handleCancel = () => this.setState({visible:false})
getPosition=(positions)=>{
    console.log("pos",positions)
  this.setState({
    position:positions,
    lat:positions,
    locationSaved: true,
    locatioError:false
  })
}
render(){
    const data = this.props.location.state.merchantData.loc ? this.props.location.state.merchantData.loc : undefined;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    let merchantData = this.props.location.state.merchantData
    // let lat1=merchantData!=null && merchantData.loc!=null && merchantData.loc.lat!==null?merchantData.loc.lat:'';
    // let lng1=merchantData!=null && merchantData.loc!=null && merchantData.loc.lng!==null?merchantData.loc.lng:'';
    console.log("query data",merchantData.loc)
    return(
        <App header={"Edit Merchant"}>
           <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Business Name">
                                {getFieldDecorator(`businessName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: merchantData.businessName?merchantData.businessName:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Business Name",
                                    },
                                ]
                                })(
                                    <Input placeholder="Business Name"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator(`email`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.email?merchantData.email:'',
                                    rules: [{
                                        type: 'email',
                                        required: true,
                                        message: "Please enter E-mail!",
                                    },
                                ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="First Name">
                                {getFieldDecorator(`fName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.fName?merchantData.fName:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter First Name",
                                    }]
                                })(
                                    <Input placeholder="First Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Last Name"
                            >
                                {getFieldDecorator(`lName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.lName?merchantData.lName:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Last Name",
                                    }]
                                })(
                                    <Input placeholder="Last Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mob`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    initialValue:merchantData.mob?merchantData.mob:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)){
                                              cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }}
                            ]
                                })(
                                    <Input placeholder="Mobile Number" disabled="true" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="GST Number"
                            >
                                {getFieldDecorator(`gstNo`, {
                                   initialValue:merchantData.gstNo ? merchantData.gstNo: null,
                                    rules: [{
                                        required: false
                                    }]
                                })(
                                    <Input placeholder="GST Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Salon Type"
                            >
                                {getFieldDecorator(`saloonType`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.saloonType?merchantData.saloonType:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Salon Type",
                                    }]
                                })(
                                    <Select placeholder="Select"  style={{ width: '100%', marginRight: 8 }}>
                                        {
                                            salontype.map((tvalue) => {
                                                return (<Option key={tvalue.value}>{tvalue.label}</Option >)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Address 1"
                            >
                                {getFieldDecorator(`addressLineOne`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.addressLineOne?merchantData.address.addressLineOne:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Address 1",
                                    }]
                                })(
                                    <Input placeholder="Address 1" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Address 2"
                            >
                                {getFieldDecorator(`addressLineTwo`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.addressLineTwo?merchantData.address.addressLineTwo:'',
                                    rules: [{
                                        required: false,
                                        message: "Please enter Address 2",
                                    }]
                                })(
                                    <Input placeholder="Address 2" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="City"
                            >
                                {getFieldDecorator(`city`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue: merchantData.address && merchantData.address.city?merchantData.address.city:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter city",
                                    }]
                                })(
                                    <Input placeholder="City" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="State"
                            >
                                {getFieldDecorator(`state`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.state?merchantData.address.state:'',
                                    rules: [{
                                        required: true,
                                        message: "Please enter state",
                                    }]
                                })(
                                    <Select
                                    optionFilterProp="children"
                                    placeholder="Select State"
                                    showSearch
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  >
                                    {state.map((c)=>{
                                        return(
                                          <Option key={c.value} value={c.value} >{c.label}</Option>
                                        )
                                    }) }
                                  </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Pincode"
                            >
                                {getFieldDecorator(`zip`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                   initialValue:merchantData.address && merchantData.address.zip?merchantData.address.zip:'',
                                    rules: [{
                                        required: true,
                                        message: 'Please enter pincode'
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && isNaN(value.trim())){
                                              cb('Please enter number only')
                                            }
                                            cb()
                                        }}
                                    ]
                                })(
                                    <Input placeholder="Pincode" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem 
                            label="Status"
                            >
                                {getFieldDecorator(`status`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    initialValue:merchantData.enabled ===false ? "INACTIVE" : "ACTIVE",
                                    rules: [{
                                        required: true,
                                        message: "Please select status.",
                                    }],
                                })(
                                    <Select placeholder="Select"  style={{ width: '100%', marginRight: 8 }}>
                                        {
                                            enabled.map((tvalue) => {
                                                return (<Option key={tvalue.value} value={tvalue.value}>{tvalue.label}</Option >)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                        <Form.Item label="Services">
                            {getFieldDecorator('services', {
                                valuePropName: 'defaultValue',
                                initialValue: merchantData.services?merchantData.services:'',
                    
                                rules: [
                                { required: true, message: 'Please select Services!', type: 'array' },
                                ],
                            })(
                                <Select mode="multiple" placeholder="select Services" mode="tags" tokenSeparators={[',']} style={{width:"100%"}}>
                                {
                                     this.state.categoryData.map((val)=>{
                                    return(
                                        <Option value={val.slugServiceName}>{val.serviceName}</Option>
                                    )
                                    })
                                }
                                </Select>
                            )}
                            </Form.Item>
                        </Col>

                        
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <Form.Item label="Address Pin on map" >
                        {
                            getFieldDecorator('latlng', {
                                validateTrigger: ['onChange', 'onBlur'],
                                // rules: [
                                //     { required: true, message: 'Please select Location!' },
                                //     ],
                            //     rules: [
                            //                     {
                            // validator:(rule, value, cb)=>{
                            //     if(this.state.lat == ""){
                            //       cb('location required')
                            //     }
                            //     cb()
                            // }}
                            // ],
                            })(
                                <div>
                                <div  onClick={this.handleOpen} ><img src="https://cdn.pixabay.com/photo/2016/03/22/04/23/map-1272165__340.png" width="40" height="40"/></div>
                            <Modal
                            footer={null}
                             width='200'
                                title="Select your Location on the Map"
                                visible={this.state.visible}
                                onOk={this.handleOpen}
                                onCancel={this.handleCancel}
                                >
                            <MapComponent isMarkerShown getPosition={this.getPosition}  position={data} />

                                   
                                
                            </Modal>
                            </div>
                            )
                        }
                    </Form.Item>
                    { this.state.position !== undefined && this.state.locationSaved == null ? <p style={{color:'green'}}><Icon type="check"/> Location Updated</p> : this.state.locationSaved  && <p style={{color:'green'}}><Icon type="check"/> Location Saved</p>}
                  {this.state.lat == '' &&
                    this.state.error && (
                        <div  style={{ background: 'red' }}>
                            <div  style={{padding: '0px',margin: '0px',color: '#fff' }}>
                                <p className="header">Please select the Location Pin on Map.</p>
                            </div>
                        </div>
                    )}

                        {this.state.locatioError && <h1 style={{color:'red'}}>Please select the Location Pin on Map</h1>}

                        </Col>
                    </Row>


        
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
        </App>
    )
}
}
const WrappedEditMerchant = Form.create()(EditMerchant);
export default withApollo(WrappedEditMerchant)