// **Devel
// export default {
//   graphqlEndpoint:"https://dy4kqnbh2jf7xjyztsih4otguu.appsync-api.ap-south-1.amazonaws.com/graphql",
//   region: "ap-south-1",
//   apiKey: "da2-dfysffyvxjhirjntyjaektqhim",
//   authenticationType: "API_KEY",
//   bucket: "saloon-app-dev",
//   accessKeyId:"AKIAXJJLX32ZYH3YTHLK",
//   secretAccessKey:"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",
//   userPoolId:"ap-south-1_l5BCHW01r",
//   userPoolWebClientId:"34bhpm4o5cg6jvuur33o6mhcb2",
// };
// **Prod
export default {
  graphqlEndpoint:"https://s3gz7sgmq5fjfhibkcwec4vbdq.appsync-api.ap-south-1.amazonaws.com/graphql",
  region: "ap-south-1",
  apiKey: "da2-3jjh4wspynakdicfewgbqq37k4",
  authenticationType: "API_KEY",
  bucket: "saloon-app-prod",
  accessKeyId:"AKIA4AK2UPGZBIDIRZGR",
  secretAccessKey:"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",
  userPoolId:"ap-south-1_rC1Jo14Rr",
  userPoolWebClientId:"2fp25ckr24rtp8c8norj0q0h2e",
};
