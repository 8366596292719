import gql from 'graphql-tag'

export default gql`
mutation createAnnouncement(
    $name: String!
    $slugName: String!
    $title: String!
    $description: String
    $link: String
    $redirectTo: String
    $icon: S3ObjectInput
    $enabled: Boolean
  ){
    createAnnouncement(input:{
      name: $name
      slugName: $slugName
      title: $title
      description: $description
      link: $link
      redirectTo: $redirectTo
      enabled: $enabled
      icon: $icon
    }){
      id
      mId
      sortId
      name
      slugName
      title
      description
      link
      redirectTo
      enabled
      createdAt
      icon{
        bucket
        region
        key
      }
    }
  }`
  