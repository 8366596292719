import React, { Component } from 'react';
import { Breadcrumb, Button, Col, Table, Row,Popconfirm, Spin,message } from 'antd';
import { compose, graphql, withApollo } from 'react-apollo'

import { Link } from 'react-router-dom';
import App from '../App'
import getAnnouncements from '../query/getAnnouncements'
import deleteAnnouncement from '../mutation/deleteAnnouncement'
class AnnouncementList extends React.Component{
    constructor(props){
    super(props)
    this.state={
        merchantData:[],
        announcementList: [],
        deleteMenu:false,
        loading:false,
        Deldata:[]
    }
}
enterLoading = ()=>{
    this.setState({loading:true})
}
componentDidMount(){
  this.enterLoading()
  this.props.client.query({
      query:getAnnouncements,
      variables: { mId :"announcement" },
      fetchPolicy: 'network-only' ,
      })
      .then(({ data }) => {
        // console.log("data1",data)
         this.setState({
          merchantData: data.getAnnouncements.items,
          loading:false
        });
      })
      .catch(err => {
          console.log(`Error ${JSON.stringify(err)}`)
      })
  }


  handleDelete =(record,ref)=> {
    // console.log("deletedata",record)
    // console.log("deletedata",ref)
  this.props.client.mutate({
    mutation: deleteAnnouncement,
    variables:{
    //  id:record.id,
    //  mId:record.mId,
     sortId:record.sortId,
    //  slugName:record.slugName
    }
  }).then(({data})=>{
    //   console.log("app data",data)
    if(data.deleteAnnouncement){
      let arr = this.state.merchantData
    //   console.log('arr',arr)
      arr.splice(record.key,1)
      this.setState({
        merchantData:arr
      })
      message.success(" Announcement deleted successfully")
      
    }
})
}
columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width:'20%'
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width:'20%'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width:'40%'
    },
    // {
    //     title: 'Delay',
    //     dataIndex: 'delay',
    //     key: 'delay',
    //     width:'10%'
    // },
    {
        title: 'Action',
        key: 'action',
        width:'10%',
        render: (data) => {
            // console.log("data table",data)
                return (
                    <span >
                        <Link to={{pathname:"/update_announcment", state:{merchantData: data}}}>
                            <Button type="default" icon="edit" style={{ cursor: "pointer", background:"#389e0d", color:"#fff"}} />

                        </Link>
                        <Popconfirm title="Sure to Delete?" onConfirm={() => this.handleDelete(data)}>
                                            <Button icon="delete" type="primary" style={{marginLeft:"5px",ursor: "pointer", background:"#e02319"}}/>
                                        </Popconfirm>
                        
                    </span>
                )
        }
    }
];

// handleDelete = (slugname) => {
//     this.props.DeleteAnnouncementAlias({
//         variables:{
//             slugName:slugname
//         }
//     })
//     .then(({ data }) => {
//         if(data.deleteAnnouncement && data.deleteAnnouncement.name){
//             message.success('Announcement deleted successfully')
//             this.props.refetch()
//         }
//     })
//     .catch(res => {
//         console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)            
//     });

// }

render(){
    // const { getAnnouncements } = this.props.data
    return(
        <App header={"Announcement List"}>
            <Row gutter={16}>
                <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}} xl={{span:23}}>
                    <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        <Breadcrumb.Item>Announcement List</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}} xl={{span:1}}>
                    <Link to={{ pathname :"/create_Announcement" }}>
                        <Button type="default" style={{ marginBottom: 16, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                            Add New Announcement
                        </Button>
                    </Link>
                </Col>
            </Row>       
        <Table 
                columns={this.columns} 
                dataSource={this.state.merchantData}
                // rowKey='id'
            />
        </App>
    )
}
}
export default withApollo(AnnouncementList)
