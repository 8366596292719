import React from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Table,message } from 'antd'
import { graphql, compose, withApollo } from 'react-apollo';
import createMerchant from '../mutation/createMerchant'
import getServiceCategories from '../query/getServiceCategories'
import App from '../App'
const FormItem = Form.Item
const Option = Select.Option;
const status = [{key:0, label:"ACTIVE", value:"true"}, {key:1, label:"INACTIVE", value:'false'}]
const salontype = [{key:0, label:"Mens", value:'MALE'},{key:1, label:"Unisex", value:'UNISEX'},]
const gender = [{key:0, label:"Male", value:'MALE'},{key:1, label:"Female", value:"FEMALE"}]
const dateFormat = 'YYYY/MM/DD';
const country = [
    { value: 'IND', label: 'India' },
    { value: 'USA', label: 'USA' },
    { value: 'UK', label: 'UK '}
]

const columns = [{
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
}, {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
}, {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
}, {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
}, {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
}]

const state = [
    {value:'JAMMU_KASHMIR',label:'JAMMU KASHMIR'},
    {value:'HIMACHAL_PRADESH',label:'HIMACHAL PRADESH'},
    {value:'PUNJAB',label:'PUNJAB'},
    {value:'DELHI',label:'DELHI'},
    {value:'RAJASTHAN',label:'RAJASTHAN'},
    {value:'UTTARAKHAND',label:'UTTARAKHAND'},
    {value:'UTTAR_PRADESH',label:'UTTAR PRADESH'},
    {value:'WEST_BENGAL',label:'WEST BENGAL'},
    {value:'BIHAR',label:'BIHAR'},
    {value:'JHARKHAND',label:'JHARKHAND'},
    {value:'ODISHA',label:'ODISHA'},
    {value:'ASSAM',label:'ASSAM'},
    {value:'ARUNACHAL_PRADESH',label:'ARUNACHAL PRADESH'},
    {value:'MEGHALAYA',label:'MEGHALAYA'},
    {value:'TRIPURA',label:'TRIPURA'},
    {value:'MIZORAM',label:'MIZORAM'},
    {value:'MANIPUR',label:'MANIPUR'},
    {value:'NAGALAND',label:'NAGALAND'},
    {value:'MAHARASHTRA',label:'MAHARASHTRA'},
    {value:'GUJARAT',label:'GUJARAT'},
    {value:'GOA',label:'GOA'},
    {value:'CHHATTISGARH',label:'CHHATTISGARH'},
    {value:'MADHYA_PRADESH',label:'MADHYA PRADESH'},
    {value:'ANDHRA_PRADESH',label:'ANDHRA PRADESH'},
    {value:'HARYANA',label:'HARYANA'},
    {value:'KERALA',label:'KERALA'},
    {value:'SIKKIM',label:'SIKKIM'},
    {value:'TAMIL_NADU',label:'TAMIL NADU'},
    {value:'TELANGANA',label:'TELANGANA'},
    {value:'KARNATAKA',label:'KARNATAKA'},
]
class CreateMerchant extends React.Component{

    constructor(props){
        super(props)
        this.state={
            loading:false,
            selectedZipcode: ['all'],
            selectedCity: '',
            selectedState: '',
            selectedCountry: '',
            countryData: [],
            stateData: [],
            cityData: [],
            zipData: ['all'],
            selectedLocations: [],
            locationError: false,
            dataSource:[],
            name:'',
            categoryData: [],
        }
    }
    componentDidMount(){
        this.props.client.query({
          query: getServiceCategories,
          fetchPolicy: 'network-only' 
        }).then(({data})=>{
            // console.log("category",data.getServiceCategories.items)
          //   var dd = data.getServiceCategories.items
          this.setState({
            categoryData:data.getServiceCategories.items,
          })
        }).catch((err)=>{
          console.log(err)
        })
      }
    enterLoading = ()=>{
        this.setState({
            loading:true
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            // console.log('Received values of form: ', values);
                            this.props.client.mutate({
                                mutation: createMerchant,
                                variables: {
                                    userType:values.salontype,
                                    mob:values.mob,
                                    email:values.email,
                                    fName:values.fName,
                                    lName:values.lName,
                                    businessName:values.businessName, 
                                    services:values.services,
                                    estDate: values.estDate,
                                    saloonType:values.salontype,
                                    gstNo:values.gstNo,
                                    enabled:values.enabled,
                                    address:{
                                    addressLineOne:values.addressLineOne,
                                    addressLineTwo:values.addressLineTwo,
                                    city:values.city,
                                    state:values.state,
                                    zip:values.zip,
                                    country:"IND",
                                    },
                                }
                            }).then((data) => {
                                message.success('Merchant Created Successful');
                                // console.log(data)
                                if(data.createUserRegistration){
                                message.success('Merchant Created Successful');
                                }
                                })
          }
        });
      };
      handleclick =()=>{
        this.props.history.push('/manage-merchant')
    }
    render(){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        // const stateSorted = _.sortBy(state, ['label'])
        return(
            <App header="Create Merchant">
                <Form onSubmit={this.handleSubmit}>
                    <Row gutter={16}>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Business Name">
                                {getFieldDecorator(`businessName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Business Name",
                                    },
                                    // {
                            
                                    //     validator:(rule, value, cb)=>{
                                    //       if(value){
                                    //         if(!value.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                                    //           cb('Please enter valid Email address')
                                    //         }
                                    //       }
                                    //         cb()
                                    //     }},
                                ]
                                })(
                                    <Input placeholder="Business Name"  style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator(`email`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                //    initialValue:this.state.name,
                                    rules: [{
                                        type: 'email',
                                        required: true,
                                        message: "Please enter E-mail!",
                                    },
                                    // {
                            
                                    //     validator:(rule, value, cb)=>{
                                    //       if(value){
                                    //         if(!value.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                                    //           cb('Please enter valid Email address')
                                    //         }
                                    //       }
                                    //         cb()
                                    //     }},
                                    
                                
                                ]
                                })(
                                    <Input placeholder="Email" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="First Name">
                                {getFieldDecorator(`fName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter First Name",
                                    }]
                                })(
                                    <Input placeholder="First Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Last Name"
                            >
                                {getFieldDecorator(`lName`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Last Name",
                                    }]
                                })(
                                    <Input placeholder="Last Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Mobile Number"
                            >
                                {getFieldDecorator(`mob`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [{
                                        required: true,
                                        message: "Please enter Mobile Number",
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && (isNaN(value.trim()) || value.trim().length != 10)){
                                              cb('Please enter 10 digit number only')
                                            }
                                            cb()
                                        }}
                            ]
                                })(
                                    <Input placeholder="Mobile Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="GST Number"
                            >
                                {getFieldDecorator(`gstNo`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: false
                                    }]
                                })(
                                    <Input placeholder="GST Number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Establish Date"
                            >
                                {getFieldDecorator(`estDate`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true
                                    }]
                                })(
                                    <DatePicker format={dateFormat} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Salon Type"
                            >
                                {getFieldDecorator(`salontype`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Salon Type",
                                    }]
                                })(
                                    <Select placeholder="Select"  style={{ width: '100%', marginRight: 8 }}>
                                        {
                                            salontype.map((val) => {
                                                return (<Option key={val.value}>{val.label}</Option >)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        
                        
                        
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem label="Address 1"
                            >
                                {getFieldDecorator(`addressLineOne`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter Address 1",
                                    }]
                                })(
                                    <Input placeholder="Address 1" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Address 2"
                            >
                                {getFieldDecorator(`addressLineTwo`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: false,
                                        message: "Please enter Address 2",
                                    }]
                                })(
                                    <Input placeholder="Address 2" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="City"
                            >
                                {getFieldDecorator(`city`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter city",
                                    }]
                                })(
                                    <Input placeholder="City" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="State"
                            >
                                {getFieldDecorator(`state`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: "Please enter state",
                                    }]
                                })(
                                    <Select
                                    optionFilterProp="children"
                                    placeholder="Select State"
                                    showSearch
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  >
                                    {state.map((c)=>{
                                        return(
                                          <Option key={c.value} value={c.value} >{c.label}</Option>
                                        )
                                    }) }
                                  </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Pincode"
                            >
                                {getFieldDecorator(`zip`, {
                                   trigger: 'onBlur',
                                   valuePropName: 'defaultValue',
                                    rules: [{
                                        required: true,
                                        message: 'Please enter pincode'
                                    },
                                    {
                                        validator:(rule, value, cb)=>{
                                            if( value != undefined && value != "" && isNaN(value.trim())){
                                              cb('Please enter number only')
                                            }
                                            cb()
                                        }}
                                    ]
                                })(
                                    <Input placeholder="Pincode" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                       
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem 
                            label="Status"
                            >
                                {getFieldDecorator(`enabled`, {
                                     validateTrigger: ['onChange', 'onBlur'],
                                    initialValue:"ACTIVE",
                                    rules: [{
                                        required: true,
                                        message: "Please select status.",
                                    }],
                                })(
                                    <Select placeholder="Select"  style={{ width: '100%', marginRight: 8 }}>
                                        {
                                            status.map((tvalue) => {
                                                return (<Option key={tvalue.value}>{tvalue.label}</Option >)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                        <Form.Item label="Services">
                            {getFieldDecorator('services', {
                                rules: [
                                { required: true, message: 'Please select Services!', type: 'array' },
                                ],
                            })(
                                <Select mode="multiple" placeholder="select Services" style={{width:"100%"}}>
                                {
                                     this.state.categoryData.map((val)=>{
                                        //  console.log("val",val)
                                    return(
                                        <Option value={val.slugServiceName}>{val.serviceName}</Option>
                                    )
                                    })
                                }
                                </Select>
                            )}
                            </Form.Item>
                        </Col>
                    </Row>
                   
                        {/* <Row gutter={16}>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem
                                label="Country"
                            >
                                <Select onChange={this.onCountryChange} defaultValue={this.state.selectedCountry}>
                                    <Option key='x' value=''>All countries</Option>
                                    {country.map((c) => {
                                        return (
                                            <Option key={c.value} value={c.value} >{c.label}</Option>
                                        )
                                    })}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem
                                label="State"
                            >
                                <Select showSearch onChange={this.onStateChange} defaultValue={this.state.selectedState}>
                                    <Option key='x' value=''>All states</Option>
                                    {this.state.stateData.sort().map((c, i) => {
                                        return (
                                            <Option key={i} value={c} style={{ textTransform: 'capitalize' }}>{c.toUpperCase()}</Option>
                                        )
                                    })}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem
                                label="City"
                            >
                                <Select showSearch onChange={this.onCityChange} defaultValue={this.state.selectedCity}>
                                    <Option key='x' value=''>All cities</Option>
                                    {this.state.cityData.sort().map((c, i) => {
                                        return (
                                            <Option key={i} value={c} style={{ textTransform: 'capitalize' }}>{c}</Option>
                                        )

                                    })}
                                </Select>

                            </FormItem>
                        </Col>

                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <FormItem
                                label="Zip"
                            >

                                <Select showSearch mode="multiple" onChange={this.onZipcodeChange} defaultValue={this.state.selectedZipcode}>
                                <Option key='x' value='all'>All zips</Option>
                                    {this.state.zipData.map((c, i) => {

                                        return (
                                            <Option key={i} value={c}>{c}</Option>
                                        )

                                    })}
                                </Select>

                            </FormItem>
                        </Col>
                        <br />
                        <Col xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}>
                            <Button type="primary" onClick={this.handleLocationForm} style={{ background:"#389e0d", color:"#fff",marginTop: '7%',marginBottom: '7%' }}>Save Location</Button>
                        </Col>
                    </Row> */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }}  onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
            </App>
        )
    }
}
const WrappedCreateMerchant = Form.create()(CreateMerchant);

const CreateMerchantcompres =  compose(
    graphql(createMerchant)
  )(WrappedCreateMerchant)
  
export default withApollo(CreateMerchantcompres)
