import gql from 'graphql-tag'

export default gql`
query getAnnouncements( $mId: String!){
    getAnnouncements(mId: $mId){
      items{
        mId
      sortId
      name
      slugName
      title
      description
      link
      redirectTo
      enabled
      createdAt
      icon{
        bucket
        region
        key
      }
      }
      nextToken
    }
  }
  `