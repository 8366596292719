import gql from 'graphql-tag'

export default gql`
query{
  getSiteSetting{
    mId
    sortId
    companyName
    whatsAppNum
    contactEmail
    facebook
    instagram
    youtube
    banners{
        bucket
        region
        key
      }
    location{
      lat
      lng
    }
    address{
      addressLineOne
      addressLineTwo
      city
      state
      zip
      country
    }
  }
}
`