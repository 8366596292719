import React from 'react';
import { Button, Col, Icon, Layout, Menu,  Tooltip, Row } from 'antd';
import { Auth } from 'aws-amplify';
import MediaQuery from 'react-responsive';
import { Link,withRouter } from 'react-router-dom';
import MLayout from './components/m/mLayout'
import './App.css';

const { Header, Sider,Footer, Content } = Layout;
const { SubMenu } = Menu;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      activemenuKey: '',
      defaultOpen: '',
      openKeys: []

    };
  }

            static showMenuSelected(url) {
              const pathArr = url.split('/').filter(pathVal => pathVal != '');
              const pathName = pathArr[0];
              let activeKey = '0';

              switch (pathName) {
                case undefined:
                activeKey = '9';
                break;
                case 'merchant':
                activeKey = '1';
                break;
                
                case 'editmerchant':
                activeKey = '1';
                break;

                case 'userlist':
                activeKey = '2';
                break;

                case 'customeremail':
                activeKey = '2';
                break;
                
                case 'homepage':
                activeKey = '5';
                break;

                case 'createMarchant':
                activeKey = '1';
                break;
                
                case 'manageAppointment':
                activeKey = '3';
                break;

                case 'customerreviews':
                activeKey = '4';
                break;

                case 'announcements':
                activeKey = '6';
                break;

                case 'create_Announcement':
                activeKey = '6';
                break;
                case 'merchantRe':
                activeKey = '9';
                break;
                case 'merchantreqEdit' :
                activeKey = '9';
                break;
                case 'update_announcment':
                activeKey = '6';
                break;
                case 'setting':
                activeKey = '7';
                break;
                case 'create_services':
                activeKey = '8';
                break;
                case 'manage_services':
                activeKey = '8';
                break;
                case 'edit_service' :
                activeKey = '8'
                break;
                case 'sub_services' :
                activeKey = '8'
                break;
                default:
                  activeKey = '4';
              }
              return {
                activeKey
              };
            }
            static getDerivedStateFromProps(nextProps, nextState) {
              const getActiveMenuId = App.showMenuSelected(nextProps.match.url);
              return {
                activemenuKey: getActiveMenuId.activeKey,
              };
          
          }

            toggle = () => {
              this.setState({
                collapsed: !this.state.collapsed,
              });
            };

          handleClick = (e) => {
            console.log('click ', e);
            console.log('key',e.keyPath[1])
        
          }
          handleSignOut = () => {
            localStorage.removeItem("role");
            localStorage.removeItem("terms"); 
               Auth.signOut()
              .then(() => {
                window.location = window.location.origin;
                // window.location.reload(true)
              })
              .catch(err => console.log(err));
          };
          onCollapse = collapsed => {
            this.setState({ collapsed });
          };
          onOpenChange = openKeys => {
            const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
              this.setState({ openKeys });
            } else {
              this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
              });
            }
          };
 
  render(){
    const { children} = this.props;
    const { activemenuKey } = this.state;
  return (
    // <MediaQuery minDeviceWidth={700}>
    //   {matches => {
    //       if (matches) {
            // return (
              // <>
                <Layout>
                  <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={(broken) => { console.log(broken); }}
                    onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
                  >
                       <div
                    className="logo"
                    style={{
                      height: '37px',
                      margin: '16px'
                    }}
                  >
                    {/* {this.state.collapsed ? (
                      <img
                        src="https://www.recaho.com/img/xyz/logosalon.png"
                        className="header-logo"
                        style={{ width: '30px' }}
                      />
                    ) : ( */}
                      <img
                        src={require("./img/recaho2.png")}
                        style={{ width: '100%', height: '40px' }}
                      />
                    {/* )} */}
                  </div>
                    <Menu theme="dark" mode="inline" selectedKeys={[activemenuKey]}>
                      <Menu.Item key="1">
                      <Link to="/merchant">
                        <Icon type="code-sandbox" />
                        <span className="nav-text">Manage Merchant</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="9">
                        <Link to="/merchantRe">
                        <Icon type="code-sandbox" />
                        <span className="nav-text">Merchant Request</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="8">
                      <Link to="/manage_services">
                        <Icon type="code-sandbox" />
                        <span className="nav-text">Manage Services</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Link to="/userlist">
                        <Icon type="usergroup-add" />
                        <span className="nav-text">User List</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="3">
                        <Link to="/manageAppointment">
                        <Icon type="calendar" />
                        <span className="nav-text">Appointment</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="4">
                        <Link to="/customerreviews">
                        <Icon type="user" />
                        <span className="nav-text">User Reviews</span>
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item key="5">
                        <Link to="/homepage">
                          <Icon type="picture" />
                          <span className="nav-text">Home Page Banners</span>
                        </Link>
                      </Menu.Item> */}
                      <Menu.Item key="6">
                        <Link to="/announcements">
                          <Icon type="picture" />
                          <span className="nav-text">Announcement</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="7">
                        <Link to="/setting">
                          <Icon type="setting" />
                          <span className="nav-text">App Settings</span>
                        </Link>
                      </Menu.Item>
                    </Menu>
                  </Sider>
                  <Layout style={{ height: "100vh" }}>
                    <Header style={{ background: '#fff', padding: 0 }} >
                    <Row>
                      <Col span={20}>
                    {/* <Icon
                          className="trigger"
                          type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                          onClick={this.toggle}
                        /> */}
                    <h2 style={{marginLeft:'20px'}}>{this.props.header}</h2>
                      </Col>
                      <Col>
                      <div style={{cursor:"pointer", padding:"10px",float:"right"}} onClick={this.handleSignOut}>
                      <Tooltip placement="left"  title="Logout">
                      <Icon type="logout"   style={{ fontSize: '30px', color: '#08c'}}/>
                      </Tooltip>
                      </div>
                      </Col>
                    </Row>
                    </Header>
                    <Content style={{ margin: '10px 10px 0', padding: 10,
                      background: '#fff',
                      overflow: 'auto' }}>
                      <div style={{ padding: 10, background: '#fff', minHeight: 560 }}>
                      {this.props.children}
                      </div>
                    </Content>
                  </Layout>
                </Layout>
      
      // </>
            // );
    //   }else{
    //     return <MLayout siteType={this.props.siteType}>{children}</MLayout>;
    //   }}
    // }
    // </MediaQuery>
  );
}
}
export default withRouter(App);
