import React, {Fragment}  from 'react'
import { Link } from 'react-router-dom';
import {Breadcrumb, Button, Col, Dropdown, Icon, Input, Menu, Row, Select, Table} from 'antd'
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Query, graphql, compose, withApollo } from 'react-apollo';
import App from '../App'
import getConsumersByDate from '../query/getConsumersByDate'



class Userlist extends React.Component{
    constructor(props){
    super(props)
    this.state={
        searchText: '',
        electedRowKeys:this.props.keys,
        dataSource:[]
    }
}
                  // getColumnSearchProps = dataIndex => ({
                  //   filterDropdown: ({
                  //     setSelectedKeys,
                  //     selectedKeys,
                  //     confirm,
                  //     clearFilters
                  //   }) => (
                  //     <div className="custom-filter-dropdown">
                  //       <Input
                  //         ref={node => {
                  //           this.searchInput = node;
                  //         }}
                  //         placeholder={`Search here`}
                  //         value={selectedKeys[0]}
                  //         onChange={e =>
                  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
                  //         }
                  //         onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                  //         style={{ width: 188, marginBottom: 8, display: 'block' }}
                  //       />
                  //       <Button
                  //         type="primary"
                  //         onClick={() => this.handleSearch(selectedKeys, confirm)}
                  //         icon="search"
                  //         size="small"
                  //         style={{ width: 90, marginRight: 8 }}
                  //       >
                  //         Search
                  //       </Button>
                  //       <Button
                  //         onClick={() => this.handleReset(clearFilters)}
                  //         size="small"
                  //         style={{ width: 90 }}
                  //       >
                  //         Reset
                  //       </Button>
                  //     </div>
                  //   ),
                  //   filterIcon: filtered => (
                  //     <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                  //   ),
                  //   // onFilter: (value, record) => {
                  //   //   if (dataIndex == 'upn') {
                  //   //     return record[dataIndex]
                  //   //       .toString()
                  //   //       .toLowerCase()
                  //   //       .includes(value.toLowerCase());
                  //   //   } else {
                  //   //     let a = dataIndex.split('.')[1].toString();
                  //   //     return record.masterVariationDump[a]
                  //   //       .toString()
                  //   //       .toLowerCase()
                  //   //       .includes(value.toLowerCase());
                  //   //   }
                  //   // },
                  //   onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
                  //   onFilterDropdownVisibleChange: visible => {
                  //     if (visible) {
                  //       setTimeout(() => this.searchInput.select());
                  //     }
                  //   },
                  //   render: text => (
                  //     <Highlighter
                  //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  //       searchWords={[this.state.searchText]}
                  //       autoEscape
                  //       textToHighlight={text.toString()}
                  //     />
                  //   )
                  // });

                  // handleSearch = (selectedKeys, confirm) => {
                  //   confirm();
                  //   this.setState({ searchText: selectedKeys[0], onSearch: true,search:true });
                  // };

                  // handleReset = clearFilters => {
                  //   clearFilters();
                  //   this.setState({
                  //     searchText: '',
                  //     nextToken: '',
                  //     productList: [],
                  //     loading: false,
                  //     hasMore: true,
                  //     search:false
                  //   });
                  // };
                  componentDidMount(){
                    this.props.client.query({
                        query:getConsumersByDate,
                        variables:{
                          nextToken:""
                        },
                        fetchPolicy: 'network-only' ,
                        })
                        .then(({ data }) => {
                          console.log("data1",data)
                          this.createDatasource(data.getConsumersByDate.items)
                          // this.setState({
                          //   dataSource:data.getConsumersByDate
                          // })
                        },
                       
                     )
                        .catch(err => {
                            console.log(`Error ${JSON.stringify(err)}`)
                        })
                    };
                    createDatasource=(data)=>{
                              console.log("all data",data)
                      let dataSource = data.map((m,i)=>{
                      //   console.log('mob number ==',m.consumerMob)
               
                        return{
                          mob:m.mob,
                          email:m.email,
                          fName:m.fName,
                          lName:m.lName,
                          gender:m.gender,
                          createdAt:m.createdAt
                        }
                      }
                      )
                
                      this.setState({
                        dataSource:dataSource
                      })
                  }

  
render(){
    const {selectedRowKeys} = this.state
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onChangeHandle
      };
      
      
      const columns = [
        {
          title: 'First Name',
          dataIndex: 'fName',
          key: 'fname',
          width: '15%',
          // ...this.getColumnSearchProps('fname'),
          // sorter: (a, b) => {
          //   return a.firstName.localeCompare(
          //     b.firstName
          //   );
          // },
        },
        {
          title: 'Last Name',
          dataIndex: 'lName',
          key: 'lname',
          width: '15%',
          // ...this.getColumnSearchProps('lname'),
        },
        {
          title: 'Mobile',
          dataIndex: 'mob',
          key: 'mob',
          width: '15%',
          // ...this.getColumnSearchProps('mob'),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '15%',
          // ...this.getColumnSearchProps('name'),
        },
        // {
        //   title: 'Date of Birth',
        //   dataIndex: 'dob',
        //   key: 'dob',
        //   width: '15%',
        //   // ...this.getColumnSearchProps('age'),
        // },
        {
          title: 'Gender',
          dataIndex: 'gender',
          filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
          key: 'gender',
          // ...this.getColumnSearchProps('address'),
        },
        {
          title: 'Member since',
          dataIndex: 'createdAt',
          // key: 'createAt',
          render:(data)=>{
            console.log("data",data)
            var dateString = moment.unix(data).format("DD/MM/YYYY");
           
            return(
                <span>{dateString}</span>
            )
        }
          // ...this.getColumnSearchProps('address'),
        },
      ];    
    return(
        <App header={"User List"}>
            <Fragment>
            <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:23}} lg={{span:23}}>
                        <Breadcrumb style={{marginBottom:"20px"}}>
                        <Link to="/">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                            <Breadcrumb.Item>Merchant</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:1}} lg={{span:1}}>
                        <Link to="/">
                            {/* <Link to="/customeremail"> */}
                                <Button type="default" style={{ marginBottom: 16, float: "right", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                                    Create Email Campaign
                                </Button>
                            {/* </Link> */}
                        </Link>
                    </Col>
                    </Row>
              {/* {!this.props.showComp && <Col span={4}>
                <a
                onClick={()=>this.parseToCSV(this.state.data)}
                href={`data:text/csv,${encodeURI(this.state.parsedCSV)}`}
                download='products-batch-file.csv'
                style={{ display: display, marginRight: '1em', padding: '5px', borderRadius: '2px', background:"#389e0d", color:"#fff" }}>
                Download User List 
                </a> 
                <Button type="default" style={{ display: 'inline-block', marginRight: '1em' ,background:"#389e0d", color:"#fff",}} onClick={()=>this.parseToCSV(this.state.data)}>Prepare file to download</Button>
                }
              </Col>} */}
             
              {/* {this.props.showComp ? <Table dataSource={this.state.data} 
              // onRow={(record) => ({
              //   onClick: () => {
              //     this.selectRow(record);
              //   },
              // })}
              columns={columns} rowKey="key"  rowSelection={rowSelection}/>:
              <Table  dataSource={this.state.data} columns={columns} />
              } */}
              <Table columns={columns} dataSource={this.state.dataSource} />
        </Fragment>
        
        </App>
    );
}
}
export default withApollo(Userlist)