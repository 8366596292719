import React from 'react'
import { Select, Form, Input, Button, Upload, Modal, Row, Col, Radio, message,Breadcrumb, Table, Dropdown, Icon} from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import slugify from 'slugify';
import updateServiceCategory from '../mutation/updateServiceCategory'
import AppSy from '../configs/AppSy';
import App from '../App'
const FormItem = Form.Item;
const bucket = AppSy.bucket ? AppSy.bucket : 'saloon-app-dev';
const region = AppSy.region ? AppSy.region : 'ap-south-1';
class Edit_service extends React.Component{
    constructor(props){
        super(props)
        this.state={
            iconImg: this.props.location.state.merchantData.photo != null ? {
                key: this.props.location.state.merchantData.photo.key,
                uid: this.props.location.state.merchantData.photo.key,
                bucket: this.props.location.state.merchantData.photo.bucket,
                region: this.props.location.state.merchantData.photo.region,
                status: 'done',
                url: `https://${this.props.location.state.merchantData.photo.bucket}.s3.${this.props.location.state.merchantData.photo.region}.amazonaws.com/${this.props.location.state.merchantData.photo.key}`
            }: {},
            removedIconImg: false,
            value: true,
            value1: true,
            img:[],
            loading:false,

        }
        // console.log("iconnnn",this.state.iconImg)
    }
    onChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
      onChange1 = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
          value1: e.target.value,
        });
      };
    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
                return{
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else{
                return{
                    // iconImg: {},
                    removedIconImg: false
                }
            }
        });
    }

    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return true;
    }
    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }
    componentDidMount(){
        
        let image=this.props.location.state.merchantData.photo
        // console.log("imageeettttttttt",image)
        let img1 = image != null ? {
            key: this.props.location.state.merchantData.photo.key,
            uid: this.props.location.state.merchantData.photo.key,
            bucket: this.props.location.state.merchantData.photo.bucket,
            region: this.props.location.state.merchantData.photo.region,
            status: 'done',
            url: `https://${this.props.location.state.merchantData.photo.bucket}.s3.${this.props.location.state.merchantData.photo.region}.amazonaws.com/${this.props.location.state.merchantData.photo.key}`
        }:{}
              this.setState({
                iconImg:img1,
              })
    }
    enterLoading = ()=>{
        this.setState({loading:true})
      }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
              this.enterLoading()
            // console.log('Received values of form: ', values);
            const slugName = slugify(values.serviceName, {
                lower: true
            })
            if(Object.keys(this.state.iconImg).length > 0 && !values.icon.hasOwnProperty('key')){
                // console.log("image keyyyy",this.state.iconImg.name)
                values.iconImg = {
                    bucket: bucket,
                    region: region,
                    localUri:this.state.iconImg.originFileObj,
                    key:`Category/${values.serviceName}/${this.state.iconImg.name}`,
                    mimeType:this.state.iconImg.type
                }
            }
                 else{ 
                    //  console.log("key",this.state.iconImg)
                    values.iconImg = {
                    bucket: bucket,
                    region: region,
                    // localUri:this.state.iconImg.originFileObj,
                    key:this.state.iconImg.key
                    // mimeType:this.state.iconImg.type
                    
                 };
            }
                            this.props.client.mutate({
                                mutation: updateServiceCategory,
                                variables: {
                                    serviceId:"category",
                                    slugServiceName: slugName,
                                    serviceName: values.serviceName,
                                    enabled:values.status,
                                    type: "category",
                                    searchFiled: values.serviceName.toLowerCase(),
                                    photo:values.iconImg && Object.keys(values.iconImg).length > 0 ? values.iconImg : values.photo,
                                }
                            }).then((data) => {
                                // console.log("newwww",data)
                                // message.info('data sumitted sucessfully');
                                if(data.data.updateServiceCategory){
                                message.info('service update sucessfully',this.props.history.push('/manage_services'));
                                this.setState({

                                    loading:false
                                  })
                                }
                                })
          }
        });
      };
      handleclick =()=>{
        this.props.history.push('/manage_services')
    }

    render(){
        var merchantData = this.props.location.state.merchantData
        const { getFieldDecorator } = this.props.form;
        const { previewVisible, previewImage, iconImg } = this.state;
        // console.log("editservice",merchantData.photo)
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">
                    Upload
                </div>
            </div>
        );
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return(
            <App header={"Sub Services"}>
               <Form layout='vertical' onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                            <FormItem
                                label="Service Name"
                            >
                                {getFieldDecorator(`serviceName`, {
                                   initialValue: merchantData.serviceName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Service Name",
                                    }]
                                })(
                                    <Input placeholder="Service Name" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
              
                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Status" {...formItemLayout}>
                          {getFieldDecorator('status', {
                              initialValue:merchantData.enabled===true ?true:false,
                          })(
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio value={true}>Active</Radio>
                                <Radio value={false}>Inactive</Radio>
        </Radio.Group>)}
                      </FormItem>
                      </Col>


                      <Col  xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}} >
                      <FormItem label="Photo" {...formItemLayout} extra="Add square image.">
                            {getFieldDecorator('icon', {
                                initialValue:merchantData.photo,
                                rules: [{
                                    required: false
                                }],
                            })(
                                <Upload
                                    listType="picture-card"
                                    defaultFileList={Object.keys(this.state.iconImg).length != 0 ? [this.state.iconImg] : []}
                                    data={this.state.iconImg}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleMainImage}
                                    onRemove={this.handleMainImageRemove}
                                >
                                    {Object.keys(iconImg).length != 0 ? null : uploadButton}
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </FormItem>
                      </Col>
                      {/* <Table columns={columns} loading={this.state.loading} dataSource={merchantData} /> */}
                      
                      </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                        </Form>
            </App>
        );
    }
}
const WrappedEdit_service = Form.create()(Edit_service);
export default withApollo(WrappedEdit_service)
