import gql from 'graphql-tag'
export default gql`
query getAppointments( $skips: Int $limits: Int $status: APPOINTMENT_STATUS!){
    getAppointments( skips: $skips limits: $limits status: $status){
      id
      appointmentId
      consumerMob
      consumerName
      apptDate
      services
      timeSlot
      status
      merchantId
      merchantMob
      isRead
      cancelMessage
      approvedAt
      rejectedAt
      completedAt
      createdAt
      seenAt
      merchantInfo{
      businessName
      }
      review{
        merchantId
        custId
        appointmentId
        custName
        rate
        comment
        enabled
        createdAt
    }
  }
  }
  `