import React from 'react'
import { Icon, Button, Spin, Breadcrumb, Row,Col, Table, Modal, Popconfirm, Form, Input,message } from 'antd';
import moment from 'moment';

class ViModel extends React.Component{
    constructor(props){
        super(props)
        this.state={
 
        }
    }
    render(){
        // console.log("data",this.props.d)
        // console.log("data sam",this.props.d)
        let data = this.props.d
        return(
            <Row>
            <Col span={8}>
                <h3 style={{margin:0}}>Date Received:</h3>
                <div>{moment.unix(data.createdAt).format("DD/MM/YYYY")}</div>
                <h3 style={{margin:0, marginTop:'20px'}}>First Name:</h3>
                <div>{data.fName}</div>
                <h3 style={{margin:0, marginTop:'20px'}}>Last Name:</h3>
                <div>{data.lName}</div>
                <h3 style={{margin:0, marginTop:'20px'}}>Email Id:</h3>
                <div>{data.email}</div>
                <h3 style={{margin:0,marginTop:'20px'}}>Address:</h3>
                <div>{data.address.addressLineOne}</div>
                <div>{data.address.addressLineTwo}</div>
                <div>{data.address.city}</div>
                <div>{data.address.state}</div>
                <div>{data.address.zip}</div>

                     {/* <h3 style={{margin:0, marginTop:'20px'}}>Interested In</h3>
                <div style={{width: '200px', height: '80px', overflowY: 'auto'}}>
                        {data.interestedIn != undefined && data.interestedIn.length != 0 && data.interestedIn.map((val, i)=>{
                        return(
                            <div style={{ textTransform: 'capitalize'}}>
                                {val.replace(/(?!^)\_/g, ' ').toLowerCase()}
                            </div>
                        )
                })}</div>  */}
            </Col>
            <Col span={8}>
                <h3 style={{margin:0}}>Business Name:</h3>
                <div>{data.businessName}</div>
                <h3 style={{margin:0, marginTop:'20px'}}>Mobile Number:</h3>
                <div>{data.mob}</div>
                <h3 style={{margin:0, marginTop:'20px'}}>GST No:</h3>
                <div>{data.gstNo != undefined && data.gstNo != null ? <span>{data.gstNo}</span> : '--'}</div>    
                <h3 style={{margin:0, marginTop:'20px'}}>Salon Type</h3>
                <div>{data.saloonType}</div>    
                <h3 style={{margin:0, marginTop:'20px'}}>Services</h3>
                    {data.services != undefined && data.services.map((val)=>{
                        return(
                            <>
                                <span style={{ textTransform: 'capitalize'}}>{val}</span><br/>
                            </>
                        )
                    })}
            </Col>
            <Col span={8}>
                {/* <h3 style={{margin:0}}>Name Of Top Songs</h3>
                    {data.nameOfTopSongs != undefined && data.nameOfTopSongs.map((val)=>{
                        return(
                            <>
                                <span style={{ textTransform: 'capitalize'}}>{val}</span><br/>
                            </>
                        )
                    })} */}
                    <h3  style={{margin:0}} >Certificate</h3>
                    {data.certificate != undefined && data.certificate != null ? <img alt="img" src={ `https://${data.certificate.bucket}.s3.${data.certificate.region}.amazonaws.com/${data.certificate.key}`} width="170px" height="250px"/>: '--'}
            </Col>
        </Row>
        );
    }
}
export default ViModel

// {data.mainImage.metaAttached.key}