import gql from 'graphql-tag'

export default gql`
query getMerchants( $status: String! $nextToken: String $limit: Int){
  getMerchants(status: $status nextToken: $nextToken limit: $limit){
    items{
      id
      userType
      businessName
      mob
      email
      fName
      lName
      services
      refId
      status 
      saloonType
      gstNo
      enabled
      address{
        addressLineOne
        addressLineTwo
        city
        state
        zip
        country
      }
    loc{
      lat
      lng
    }
    area
    workingDays
    workingTimeFrom
    workingTimeTo
    createdAt
    mainImage{
      bucket
      region
      key
    }
    additionalImages{
      bucket
      region
      key
    }
    certificate{
      bucket
      region
      key
    }
  }
    nextToken
  }
}

`