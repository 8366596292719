import React from 'react';
import { Link,withRouter } from 'react-router-dom';
import { Drawer, List, NavBar, Button } from 'antd-mobile';
import { Icon } from 'antd';
// import { Auth } from 'aws-amplify';

class MLayout extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        activemenuKey: 0,
        domain: ''
      };
    }
    // handleSignOut = () => {
    //     Auth.signOut()
    //       .then(() => window.location.reload(true))
    //       .catch(err => console.log(err));
    //   };
      onOpenChange = () => {
        this.setState({ collapsed: !this.state.collapsed });
      };
    
    render() {
        const { children } = this.props;
        const { activemenuKey } = this.state;
        const menuList = [
            {
              title: 'Menus',
              to: '/menus',
              icon: 'menu-unfold'
            },
            {
              title: 'Home Page Images',
              to: '/manageAppointment',
              icon: 'picture'
            },
            {
              title: 'Collections',
              to: '/collection',
              icon: 'credit-card'
            },
           
            {
              title: 'Appointments',
              to: '/manageAppointment',
              icon: 'picture',
              siteType: 'JEWEL_EXPO_PREMIUM'
            }
          ];
          const sidebar = menuList.map((m, i) => {
            if (m.siteType) {
              if (m.siteType == this.props.siteType) {
                return (
                  <List.Item
                    key={i}
                    style={{ color: '#ffffffa6', background: 'none', border: 'none' }}
                  >
                    {/* <Link to={m.to}> */}
                      <Icon type={m.icon} style={{ color: '#ffffffa6' }} />
                      <span
                        className="nav-text"
                        style={{
                          marginLeft: '10px',
                          color: '#ffffffa6',
                          fontSize: '14px'
                        }}
                      >
                        {m.title}
                      </span>
                    {/* </Link> */}
                  </List.Item>
                );
              } else {
                return null;
              }
            } else {
              return (
                <List.Item
                  key={i}
                  style={{ color: '#ffffffa6', background: 'none', border: 'none' }}
                >
                  {/* <Link to={m.to}> */}
                    <Icon type={m.icon} style={{ color: '#ffffffa6' }} />
                    <span
                      className="nav-text"
                      style={{
                        marginLeft: '10px',
                        color: '#ffffffa6',
                        fontSize: '14px'
                      }}
                    >
                      {m.title}
                    </span>
                  {/* </Link> */}
                </List.Item>
              );
            }
          });
      
          sidebar.push(
            <List.Item
              key={'logout'}
              style={{ color: '#ffffffa6', background: 'none', border: 'none' }}
            >
              <Icon type="logout" style={{ color: '#ffffffa6' }} />
              <span
                // onClick={this.handleSignOut}
                className="nav-text"
                style={{ marginLeft: '10px', color: '#ffffffa6', fontSize: '14px' }}
              >
                Logout
              </span>
            </List.Item>
            // <List.Item key={'a'}>
            //   <span className="nav-text">Version 1.0.6</span>
            // </List.Item>
          );
          return (
            <div>
              <NavBar
                mode="light"
                leftContent={[
                  <div
                    style={{ position: 'absolute', width: '30%', left: '14%' }}
                    key={2}
                  >
                    {/* <Link to="/"> */}
                      <img
                        src="https://www.recaho.com/img/recaho2.png"
                        style={{ width: '150px', height: '35px' }}
                      />
                    {/* </Link> */}
                  </div>,
                  <div key={1} onClick={this.onOpenChange}>
                    <Icon
                      type="menu-fold"
                      style={{ fontSize: '20px', color: 'black' }}
                    />
                  </div>
                ]}
              />
      
              <Drawer
                className="my-drawer"
                style={{
                  minHeight: document.documentElement.clientHeight,
                  marginTop: '45px'
                }}
                contentStyle={{
                  color: '#A6A6A6',
                  textAlign: 'center',
                  padding: 20,
                  background: '#ffff'
                }}
                sidebar={sidebar}
                open={this.state.collapsed}
                onOpenChange={this.onOpenChange}
                sidebarStyle={{ background: '#001529', zIndex: '5' }}
              >
                {children}
              </Drawer>
            </div>
          );
        }
      }
      
      export default withRouter(MLayout);
      
      