import React from 'react'
import { Select, Form, Input, Button, InputNumber, Upload, Modal, Row, Col, Cascader, message,Breadcrumb, Menu, Dropdown, Icon} from 'antd';
import App from '../App'
import AppSy from '../configs/AppSy';

import { graphql, compose,withApollo} from 'react-apollo';

import createAnnouncement from '../mutation/createAnnouncement'
import { Link } from 'react-router-dom'
import slugify from 'slugify';
import _ from 'lodash'
import uuidv1 from 'uuid/v1';
const FormItem = Form.Item;
const bucket = AppSy.bucket ? AppSy.bucket : 'saloon-app-dev';
const region = AppSy.region ? AppSy.region : 'ap-south-1';
const { TextArea } = Input;

class CreateAnnouncement extends React.Component{
    constructor(props){
        super(props)
        this.state={
            loading: false,
            iconImg: {},
        }
    }
    
    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
                return{
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else{
                return{
                    // iconImg: {},
                    removedIconImg: false
                }
            }
        });
    }

    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return true;
    }
    
    handleCancelBtn = ()=>{
        this.props.history.push('/announcements');
    }

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }
   
    handleBannerChange = ({ fileList }) => this.setState({ fileList })

    enterLoading = () => {
      this.setState({ loading: true });
    }

    messageClose = () => {
      this.props.history.push('/announcements');
    // this.setState({loading:false})
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            // console.log('Received values of form: ', values);
            if(Object.keys(this.state.iconImg).length > 0){
                values.iconImg = {
                    bucket: bucket,
                    region: region,
                    localUri:this.state.iconImg.originFileObj,
                    key:`Announcement/${values.name}/${this.state.iconImg.name}`,
                    mimeType:this.state.iconImg.type
                } 
              }
                            this.props.client.mutate({
                                mutation: createAnnouncement,
                                variables: {
                                    name: values.name,
                                    slugName:values.name,
                                    title: values.title,
                                    description: values.description,
                                    link: values.link,
                                    redirectTo: values.redirectTo ? values.redirectTo : null,
                                    enabled: true,
                                    icon:values.iconImg && Object.keys(values.iconImg).length > 0 ? values.iconImg : undefined,
                                }
                            }).then((data) => {
                                // console.log(data)
                                message.success('Announcement Created sucessfully');
                                
                                })
          }
        });
      };

    render(){
        const { getFieldDecorator } = this.props.form;
        const { previewVisible, previewImage, iconImg } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">
                    Upload
                </div>
            </div>
        );
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return(
            <App header={"Create Announcement"}>
               <Breadcrumb style={{marginBottom:"20px"}}>
            <Link to="/">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Link to="/announcements">
                <Breadcrumb.Item>Announcements</Breadcrumb.Item>
            </Link>
                <Breadcrumb.Item>Add New Announcement</Breadcrumb.Item>
            </Breadcrumb>
                <div>
                    <Form layout='vertical' onSubmit={this.handleSubmit}>
                      
                        <FormItem label="Name" {...formItemLayout}>
                            {getFieldDecorator('name', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input name",
                                }],
                            })(
                                <Input placeholder="Name" />
                            )}
                        </FormItem>
                        <FormItem label="Title" {...formItemLayout}>
                            {getFieldDecorator('title', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input title",
                                }],
                            })(
                                <Input placeholder="Title" />
                            )}
                        </FormItem>
                        
                        <FormItem label="Description" {...formItemLayout}>
                            {getFieldDecorator('description', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input description",
                                }],
                            })(
                                <TextArea onChange={this.onChangeText} placeholder="Description" />
                            )}
                        </FormItem>

                        <FormItem label="Link" {...formItemLayout} extra="Announcement will open on this link.">
                            {getFieldDecorator('link', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                rules: [{
                                    whitespace: true,
                                    required: true,
                                    message: "Please input link",
                                }],                                
                            })(
                                <Input placeholder="Link"  />
                            )}
                        </FormItem>

                        <FormItem label="Redirect to" {...formItemLayout} extra="On click of the announcement it will open this page.">
                            {getFieldDecorator('redirectTo', {
                                trigger: 'onBlur',
                                valuePropName: 'defaultValue',
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <Input placeholder="Redirect to"  />
                            )}
                        </FormItem>

                        <FormItem label="Icon" {...formItemLayout} extra="Add square image.">
                            {getFieldDecorator('icon', {
                                validateTrigger: ['onChange','onBlur'],
                                rules: [{
                                    required: true
                                }],
                            })(
                                <Upload
                                    listType="picture-card"
                                    data={iconImg}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleMainImage}
                                    onRemove={this.handleMainImageRemove}
                                >
                                    {Object.keys(iconImg).length != 0 ? null : uploadButton}
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </FormItem>
                        {/* <FormItem label="Delay in seconds" {...formItemLayout} extra="Announcement will open after the given seconds.">
                            {getFieldDecorator('delay', {
                                initialValue:1,
                                rules: [{
                                    required: false,
                                }],
                            })(
                                <InputNumber min={0} max={60} placeholder="Delay in seconds" style={{width:"100%"}} />
                            )}
                        </FormItem> */}


                        <Button type="primary"  style={{background:"#389e0d", color:"#fff"}} htmlType="submit" >Submit</Button>
                        <Button type="default" style={{background:"#f44336", color:"#fff",marginLeft:"20px"}} onClick={this.handleCancelBtn}>Cancel</Button>
                    </Form>
                </div>
            </App>
        );
    }
}

const WrappedAcc = Form.create()(CreateAnnouncement);

export default withApollo(WrappedAcc)