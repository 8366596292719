import React from 'react'
import { Select, Form, Input, Button, Upload, Modal, Row, Col, Radio, message,Breadcrumb, Table, Switch, Icon} from 'antd';
import { graphql, compose, withApollo } from 'react-apollo';
import slugify from 'slugify';
import moment from 'moment';
import updateServiceCategory from '../mutation/updateServiceCategory'
import AppSy from '../configs/AppSy';
import App from '../App'
const FormItem = Form.Item;
const bucket = AppSy.bucket ? AppSy.bucket : 'saloon-app-dev';
const region = AppSy.region ? AppSy.region : 'ap-south-1';
class Sub_servicelist extends React.Component{
    constructor(props){
        super(props)
        this.state={
            iconImg: {},
            removedIconImg: false,
            value: true,
            value1: true,
            dataSource:this.props.location.state.merchantData.serviceSubCategories,
        }
    }
    onChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
          value: e.target.value,
        });
      };
      onChange1 = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
          value1: e.target.value,
        });
      };
    handleMainImage = ({ file }) => {
        this.setState((prevState, props) => {
            if(Object.keys(prevState.iconImg).length === 0 && prevState.removedIconImg === false){
                return{
                    iconImg: file,
                    removedIconImg: false
                }
            }
            else{
                return{
                    // iconImg: {},
                    removedIconImg: false
                }
            }
        });
    }

    handleMainImageRemove = (file) => {
        this.setState({ iconImg: {}, removedIconImg: true })
        return true;
    }
    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

      submitMut = (e,val) =>{
        // console.log('val',val)
        // delete val.photo.__typename
        this.props.client.mutate({
          mutation: updateServiceCategory,
            variables:{
              serviceId: val.serviceId,
              slugServiceName:val.slugServiceName,
              serviceName:val.serviceName,
              enabled:e,
              type:val.type,
              createdAt:val.createdAt,
              photo:{
                bucket:val.photo.bucket,
                region:val.photo.region,
                key:val.photo.key
              }
            }
        }).then(({data})=>{
          // console.log('data',data)
          message.success(<span style={{fontSize:'20px'}}>Status Changed</span>)
          // console.log('delet ada',this.state.dataSource)
            let dataVal = this.state.dataSource
            // console.log("active data",dataVal)
            //  delete val.photo.__typename
            if(data.updateServiceCategory){
                dataVal.forEach(vals => {
                    if (
                        vals.serviceName == val.serviceName
                        ) {
                            vals['enabled'] =  e
                        }
                    });
                }
                this.setState({dataSource:dataVal},()=>{
  
                })
        }).catch((err)=>{
            console.log(err)
        })
    }
  

    render(){
        var serviceN = this.props
        console.log("sub Service data Name",serviceN)
        const { getFieldDecorator } = this.props.form;
        const { previewVisible, previewImage, iconImg } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">
                    Upload
                </div>
            </div>
        );
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        const columns = [ 
            {
                title: 'Date',
                dataIndex: 'createdAt',
                key:'createdAt',
                width:'10%',
                sorter: (a, b) => {
                  return a.createdAt -  b.createdAt
                },
                render: (date) => {
                  var dateString = moment.unix(date).format("DD/MM/YYYY");
                  // console.log(date,dateString)
                  return(
  
                      <span>{dateString}</span>
                  )
              }
              },
            {
              title: 'Sub Service Name',
              dataIndex: 'serviceName',
              key: 'serviceName',
              width:'20%'
              // ...this.getColumnSearchProps('fName'),
            },
            {
                title: 'Rate',
                dataIndex: 'rate',
                key: 'rate',
                width:'10%'
                // ...this.getColumnSearchProps('fName'),
              },
            {
                title: 'Description',
                dataIndex: 'description',
                key:'description',
                width:'15%'
            },
            {
              title: 'Merchant Business Name',
              dataIndex: 'merchantDetails.businessName',
              key:'description',
              width:'20%'
          },
            // {
            //     title: 'Image',
            //     dataIndex: 'photo',
            //     width:'10%',
            //     render: photo => {
            //         // console.log("photo",photo);
            //       return photo != null  ? (
            //         <img
            //           // src={'https://ppl-inida-dev.s3.us-east-2.amazonaws.com/'+ photo.key}
            //           src={ `https://${photo.bucket}.s3.${photo.region}.amazonaws.com/${photo.key}`}
            //           style={{ width: '70px' }}
            //         />
                    
            //       ) : (
            //         ''
            //       );
            //     }
            //   }, 
              {
                title: 'Status',
                key:'status',
                width:'15%',
                render:(record,i)=>{
                  // console.log("record",record);
                    return(
                         <div style={{ marginTop: 10 }}>
                            <Switch onChange={(e)=>this.submitMut(e,record)} checked={record.enabled} />
                            {/* <Icon type="bulb" size="large" theme="filled" style={{ marginLeft:"20px",fontSize: '20px', color: data.enabled ? 'green' : 'red'}} /> */}
                        </div>
                    )
    
                }
    
             },
  
            //  {
            //           title: 'Action',
            //           key: 'action',
            //           render: (data) => {
            //                   return (
            //                       <span>
            //                             <Radio.Group onChange={this.onChange1} value={this.state.value1}>
            //                                 <Radio value={true}>Active</Radio>
            //                                 <Radio value={false}>Inactive</Radio>
            //                             </Radio.Group>
            //                           {/* <Link to={{pathname:"/sub_services",
            //                               state:{merchantData:data}
            //                           }}>
            //                               <Button type="primary" icon="eye" style={{ cursor: "pointer",marginRight:"10px" }}/>
            //                           </Link>
            //                           <Link to={{pathname:"/sub_services",
            //                               state:{merchantData:data}
            //                           }}>
            //                               <Button type="primary" icon="edit" style={{ cursor: "pointer",marginRight:"10px" }}/>
            //                           </Link> */}
                                      
            //                           {/* <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(data.mob,data.userName)}>
            //                               <Button icon="delete" type= "danger" style={{marginLeft:"5px",ursor: "pointer", background:"#ff0000 ", color:"#fff"}}/>
            //                           </Popconfirm> */}
            //                       </span>
            //                   )
            //               }
                      
            //   }
  
          ];
        return(
            <App header={"Sub Services"}>
                <Row gutter={16}>
                <h2>{serviceN.serviceName} Sub Services</h2>
                      <Table columns={columns} loading={this.state.loading} dataSource={this.state.dataSource} />
                      
                      </Row>
                    
            </App>
        );
    }
}
const WrappedSub_servicelist = Form.create()(Sub_servicelist);
export default withApollo(WrappedSub_servicelist)
