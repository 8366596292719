import gql from 'graphql-tag'
export default gql`
query getConsumersByDate( $nextToken: String){
    getConsumersByDate(nextToken: $nextToken){
      items{
        userType
        mob
        email
        fName
        lName
        gender
        createdAt
        address{
          addressLineOne
          addressLineTwo
          city
          state
          zip
          country
        }
      }
      nextToken
    }
  }`
  